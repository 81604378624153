.categoriesWrapper {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.categoryBtn {
  margin-right: 8px;
  margin-top: 4px;
}

.table tbody tr {
  position: relative;
  border: none !important;
}

.table tbody td:nth-child(2) {
  min-width: 330px;
}

.table tbody tr td {
  position: relative;
  z-index: 2;
}

.table tbody tr::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #ffffff;
  border-bottom: 2px solid var(--gray-file-upload);
}

.table tbody tr.activeRow::after {
  border: 2px solid var(--game-blue) !important;
}

.audioWrapper {
  height: 40px;
  display: flex;
  align-items: center;
}
.audioPlayer {
  height: 35px;
}

.tableCell {
  display: flex;
}

.activeRow {
  background: #ffffff;
}

.checkmark {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 16px;
  height: 16px;
}

.inputFile {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.inputFileBtn {
  width: 150px !important;
  height: 28px !important;
}
.inputFileBtn * {
  cursor: pointer !important;
}

.inputFileBtn input {
  color: transparent;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.inputFileBtn .imageWrapper {
  background: #f0f2ff;
}

.previewWrapper {
  display: flex;
  flex-direction: column;
}

.footerBtns {
  display: flex;
  padding-top: 12px;
  justify-content: flex-end;
}

.footerBtns button:not(:last-child) {
  margin-right: 16px;
}

.modalWrapper {
}

.dndArea {
  background: var(--pastel-blue);
  width: 100%;
  height: 172px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 10px;
  margin-top: 10px;
  border: 2px dashed var(--light-blue);
  position: relative;
}

.dndArea p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: var(--game-blue);
  letter-spacing: -0.3px !important;
}

.dndArea.canDrop {
  background: var(--green-opacity);
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.iconWrapper img {
  width: 100%;
  height: 100%;
}

.uploadHint {
  font-size: 14px !important;
  font-family: Inter, 'serif' !important;
  font-weight: 400 !important;
  letter-spacing: -0.30000001192092896px !important;
  text-align: center !important;
}
.uploadHint + .uploadHint {
  margin-top: -10px;
  margin-bottom: 5px;
}

.iconFileWrapper {
  font-size: 48px;
  color: var(--game-blue);
}

.fileName {
  font-size: var(--input-fsz);
  text-align: center;
  margin-bottom: var(--input-padding);
  color: var(--cobalt);
}

.text {
  margin-top: 8px;
}

.withFileWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.uploaderButton {
  max-width: 120px;
}

.valueText {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.radioButton {
  margin-bottom: 0 !important;
  width: 420px;
  pointer-events: none;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.buttonsWrapper .btn + .btn {
  margin-left: 8px;
}

.emptyTable {
  min-height: 200px !important;
}
