@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700&display=swap);
.typo_typo__1RniK {
  font-family: 'Roboto', sans-serif;
  color: var(--black);
  margin: 0;
  font-weight: 400;
}

.typo_typo__1RniK.typo_white__3gVag {
  font-family: 'Inter', sans-serif;
}

.typo_typo__1RniK.typo_inter__eIOae {
  font-family: 'Inter', sans-serif;
}

.typo_gray__3ybh8 {
  color: var(--gray);
}

.typo_h1__13hx4 {
  font-size: 28px;
  line-height: 32px;
}

.typo_h2__3jKYR {
  font-size: 22px;
  line-height: 25px;
}

.typo_h3__1c_1a {
  font-size: 18px;
  line-height: 21px;
}

.typo_h4__3W0ue {
  font-size: 16px;
  line-height: 18px;
}

.typo_h5__NhHQj {
  font-size: 14px;
  line-height: 16px;
}

.typo_h6__2uju3 {
  font-size: 14px;
  line-height: 16px;
}

.typo_p__4p6kA {
  font-size: 12px;
  line-height: 16px;
  font-family: Inter, serif;
}

.typo_small__2hUwb {
  font-size: 12px;
  line-height: 14px;
}

.typo_bold__tDWdd {
  font-weight: 700;
}

.typo_semi__3qEl2 {
  font-weight: 500;
}

@media all and (max-width: 1599px) {
  .typo_h1__13hx4 {
    font-size: 26px;
    line-height: 30px;
  }

  .typo_h2__3jKYR {
    font-size: 20px;
    line-height: 23px;
  }

  .typo_h3__1c_1a {
    font-size: 16px;
    line-height: 19px;
  }

  .typo_h4__3W0ue {
    font-size: 14px;
    line-height: 16px;
  }

  .typo_h5__NhHQj {
    font-size: 12px;
    line-height: 14px;
  }

  .typo_h6__2uju3 {
    font-size: 12px;
    line-height: 14px;
  }

  .typo_p__4p6kA {
    font-size: 12px;
    line-height: 16px;
  }

  .typo_small__2hUwb {
    font-size: 10px;
    line-height: 12px;
  }
}

.typo_h1__13hx4.typo_white__3gVag {
  font-size: 36px;
  line-height: 56px;
  font-weight: 700;
  letter-spacing: -2.5px;
}

.typo_h2__3jKYR.typo_white__3gVag {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -2px;
}

.typo_h3__1c_1a.typo_white__3gVag {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -2px;
}

.typo_h4__3W0ue.typo_white__3gVag {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.5px;
}

.typo_h5__NhHQj.typo_white__3gVag {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
}

.typo_h6__2uju3.typo_white__3gVag {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.5px;
}

.typo_p__4p6kA.typo_white__3gVag {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 16px;
}

.typo_white__3gVag.typo_bold__tDWdd {
  font-weight: 600 !important;
}

.field_wrapper__sLVd5 {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--input-padding);
}

.field_inputWrapper__2YuCa {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.field_wrapper__sLVd5 .ant-input,
.field_wrapper__sLVd5 .ant-picker {
  background: var(--snow);
  border-radius: 8px;
  color: var(--cobalt);
  height: var(--input-height);
  font-size: var(--input-fsz);
  padding: 4px 8px;
  border: 1px solid var(--gray-input-preview);
}

.field_wrapper__sLVd5 textarea.ant-input {
  min-height: 40px;
  resize: vertical;
}

.field_wrapper__sLVd5 .ant-switch {
  height: var(--input-height);
  font-size: var(--input-fsz);
}

.field_wrapper__sLVd5 .ant-switch .ant-switch-handle {
  width: 16px;
  height: 16px;
  top: 50%;
  margin-top: -8px;
}

.field_newFormWrapper__3u3NQ {
  height: 50px;
}
.field_newFormWrapper__3u3NQ > div {
  height: auto !important;
}
.field_wrapper__sLVd5.field_withoutBorder__3r7s9 {
  margin-bottom: 0;
}

.field_wrapper__sLVd5 .ant-picker-panel-container {
  border-radius: 8px;
}

.field_wrapper__sLVd5 .ant-input:active,
.field_wrapper__sLVd5 .ant-picker:active,
.field_wrapper__sLVd5 .ant-input-number-input:active {
  border: 1px solid var(--cobalt);
}

.field_wrapper__sLVd5 .ant-input:hover,
.field_wrapper__sLVd5 .ant-input-number-input:hover,
.field_wrapper__sLVd5 .ant-picker:hover {
  border: 1px solid var(--game-blue);
}

.field_wrapper__sLVd5 .ant-input:focus,
.field_wrapper__sLVd5 .ant-input-number-input:focus,
.field_wrapper__sLVd5 .ant-picker:focus {
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
  background: var(--snow);
  color: var(--cobalt);
}

.field_wrapper__sLVd5 .ant-input[disabled],
.field_wrapper__sLVd5 .ant-input-number-input[disabled] {
  border: 1px solid var(--border-gray);
  background: var(--gray-file-upload);
  color: var(--light-gray-font);
}

.field_label__2bD5O {
  color: var(--black);
  margin-right: 8px;
  white-space: nowrap;
}

.field_label__2bD5O.field_disabled__31M2E {
  color: var(--light-gray-font);
}

.field_errors__3wYmd {
  display: flex;
  margin-top: 5px;
  flex-direction: column;
}

.field_errors__3wYmd.field_newForm__31_W4 {
  margin-bottom: 0;
  margin-top: 5px;
  margin-left: 5px;
}

.field_error__2y3IK {
  color: #ff4d4f;
  font-size: 12px;
  margin-bottom: 5px;
}

.field_newForm__31_W4 .field_error__2y3IK {
  margin-bottom: 0;
}

.input_wrapper__3Juod {
  position: relative;
  width: 100%;
}

.input_withIcon__Zk5jC {
  padding-left: 25px !important;
}

.input_icon__34_HV {
  font-size: var(--input-fsz);
  color: var(--cobalt);
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 7px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.input_newForm__vEdUn {
  height: 50px !important;
  border-radius: 5px !important;
  outline: none !important;
  padding: 12px 16px !important;
  border: none !important;
  background: #f8f8f8 !important;
  font-size: 16px !important;
  font-weight: 400;
  color: #828b9a !important;
}

.input_newForm__vEdUn.input_withIcon__Zk5jC {
  padding-left: 36px !important;
}

.input_icon__34_HV.input_newIcon__2ceGk {
  font-size: 16px !important;
  color: var(--gray-placeholder) !important;
  top: 0;
  z-index: 1;
  left: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: none;
          transform: none;
}

.ant-btn-loading::before {
  position: relative !important;
}

.button_button__2O00Q {
  border-radius: 15px !important;
  height: var(--input-height) !important;
  padding: 5px 12px !important;
  font-size: var(--input-fsz) !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}

.button_button__2O00Q.button_link__2w0a_ {
  border-radius: 0 !important;
  color: var(--game-blue);
}

.button_button__2O00Q.button_link__2w0a_:hover,
.button_button__2O00Q.button_link__2w0a_:active,
.button_button__2O00Q.button_link__2w0a_:focus {
  color: var(--dark-game-blue);
}

.button_button__2O00Q.button_primary__2vpsn {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
}

.button_button__2O00Q.button_primary__2vpsn:hover {
  background: var(--dark-game-blue);
  border: 1px solid var(--dark-game-blue);
}

.button_button__2O00Q.button_primary__2vpsn:focus,
.button_button__2O00Q.button_primary__2vpsn:active {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  box-shadow: var(--box-shadow);
}

.button_button__2O00Q.button_primary__2vpsn[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
}

.button_button__2O00Q.button_danger__8HqHZ {
  background: var(--red);
  border: 1px solid var(--red);
}

.ant-btn-sm {
  padding: 0 7px !important;
  height: 15px !important;
  font-size: 10px !important;
}

.button_button__2O00Q.button_danger__8HqHZ:hover {
  background: var(--dark-red);
  border: 1px solid var(--dark-red);
}

.button_button__2O00Q.button_danger__8HqHZ:focus,
.button_button__2O00Q.button_danger__8HqHZ:active {
  background: var(--red);
  border: 1px solid var(--red);
  box-shadow: var(--box-shadow);
}

.button_button__2O00Q.button_danger__8HqHZ[disabled] {
  background: var(--light-red);
  color: var(--light-red-font);
  border: 1px solid var(--light-red);
}

.button_button__2O00Q.button_ghost__1SP8R {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--gray-file-upload);
}

.button_button__2O00Q.button_ghost__1SP8R:hover {
  color: var(--dark-game-blue);
  background: var(--gray-input-preview);
  border: 1px solid var(--gray-input-preview);
}

.button_button__2O00Q.button_ghost__1SP8R:focus,
.button_button__2O00Q.button_ghost__1SP8R:active {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
}

.button_button__2O00Q.button_ghost__1SP8R[disabled] {
  background: var(--snow);
  color: var(--gray-input-preview);
  border: 1px solid var(--gray-file-upload);
}

.button_button__2O00Q.button_ghost__1SP8R.button_newForm__3atNv,
.button_button__2O00Q.button_primary__2vpsn.button_newForm__3atNv {
  margin-top: 20px;
  width: 100%;
  padding: 12px 16px !important;
  height: 50px !important;
  border-radius: 4px !important;
  font-size: 18px !important;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  box-shadow: 0 20px 30px -10px #ffaa1b !important;
}

.button_button__2O00Q.button_newForm__3atNv.button_withoutShadow__R0Lkm {
  box-shadow: none !important;
}

.button_button__2O00Q.button_newForm__3atNv.button_small__2zpZd {
  font-size: 14px !important;
  max-width: 220px;
  padding: 9px 21px !important;
  height: auto !important;
  box-shadow: 0 10px 15px -5px #ffaa1b !important;
}

.button_button__2O00Q.button_newForm__3atNv.button_small__2zpZd.button_withoutShadow__R0Lkm {
  box-shadow: none !important;
}

.button_button__2O00Q.button_ghost__1SP8R.button_newForm__3atNv {
  border: 2px solid var(--game-yellow) !important;
  color: var(--game-yellow) !important;
  background: var(--snow) !important;
}

.button_button__2O00Q.button_primary__2vpsn.button_newForm__3atNv {
  border: none !important;
  background: var(--game-yellow) !important;
  color: var(--snow) !important;
}

.button_button__2O00Q.button_primary__2vpsn.button_newForm__3atNv[disabled] {
  border: none !important;
  background: var(--dark-yellow) !important;
  color: var(--snow) !important;
}

.button_button__2O00Q.button_link__2w0a_.button_newForm__3atNv {
  padding: 12px 16px !important;
  height: 50px !important;
  border-radius: 4px !important;
  background: transparent !important;
  color: var(--game-blue) !important;
  font-size: 22px !important;
  font-family: 'Inter', sans-serif;
  border: none !important;
  font-weight: 400;
  text-decoration: underline;
}

.button_button__2O00Q.button_link__2w0a_.button_newForm__3atNv:hover {
  text-decoration: underline;
}

.toggler_wrapper__3yqMl .ant-switch-checked {
  background: var(--game-blue);
}

.toggler_wrapper__3yqMl .toggler_label__3rGiT {
  color: var(--black);
  margin-right: 8px;
}

.toggler_wrapper__3yqMl.toggler_bad__MxEhb .ant-switch-checked {
  background: var(--red);
}

.toggler_wrapper__3yqMl.toggler_good__2_d18 .ant-switch {
  background: var(--game-green);
}

.upload_modal__oS77t {
  width: 1000px !important;
}

.upload_title__3ogsE {
}

.upload_footerWrapper__2clOq {
  width: 100%;
  justify-content: center;
  display: flex;
}

.upload_footer__Op1FT {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--light-gray-border);
  width: 90%;
  padding-top: 40px;
  margin-bottom: 24px;
}

.upload_cropperFooter__2n5qp {
  margin-top: 10px;
}

.upload_cropperFooter__2n5qp .upload_footer__Op1FT button + button {
  margin-left: 5px;
}

.upload_footer__Op1FT button {
  margin-top: 16px !important;
  width: 200px !important;
}

.upload_modalTitle__26Pse {
  font-weight: 500;
  font-size: 32px;
  letter-spacing: -1.6px;
  margin-top: 32px;
  font-family: 'Inter', serif;
}

.upload_tabsWrapper__35tGa {
  display: flex;
  margin: 36px 0 36px;
}

.upload_tab__PgCrE {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--border-gray);
}

.upload_tab__PgCrE .upload_label__3ovvS {
  font-size: 16px;
  line-height: 110%;
  color: var(--gray-500);
}

.upload_tab__PgCrE .upload_tabIcon__lXhKi {
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.upload_tab__PgCrE .upload_tabIcon__lXhKi svg {
  fill: var(--gray-500);
}

.upload_tab__PgCrE.upload_active__1uOKD .upload_tabIcon__lXhKi svg {
  fill: var(--game-blue);
}

.upload_tab__PgCrE.upload_active__1uOKD p {
  color: #040415;
}

.upload_tab__PgCrE.upload_active__1uOKD {
  border-bottom: 2px solid var(--game-blue);
}

.upload_tab__PgCrE.upload_disabled__cQG7K {
  cursor: not-allowed;
}

.upload_tab__PgCrE.upload_disabled__cQG7K .upload_label__3ovvS,
.upload_tab__PgCrE.upload_disabled__cQG7K .upload_tabIcon__lXhKi {
  color: var(--gray-200);
}

.upload_sizesInput__3tivQ * {
  color: var(--cobalt) !important;
}
.upload_sizesInput__3tivQ input {
  background: var(--snow) !important;
  border: 1px solid var(--gray-input-preview) !important;
}

.upload_actionsWrapper__155y7 {
  display: flex;
}
.upload_action__18JRc {
  color: var(--game-blue) !important;
  background: var(--snow) !important;
  border: 1px solid var(--light-gray-border) !important;
  width: 120px !important;
  height: 32px !important;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.upload_action__18JRc:first-child {
  border-radius: 16px 0 0 16px !important;
  border-right: 0 !important;
}

.upload_action__18JRc:last-child {
  border-left: 0 !important;
  border-radius: 0 16px 16px 0 !important;
}

.upload_action__18JRc.upload_active__1uOKD {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

.upload_removeBgBtn__1DHFA {
  margin: 32px auto 0 auto;
  width: 200px;
}

.upload_uploader__31YWG {
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 1px solid var(--border-gray);
  display: inline-block;
}

.upload_uploader__31YWG.upload_disabled__cQG7K,
.upload_uploader__31YWG.upload_empty__2KV41.upload_disabled__cQG7K,
.upload_uploader__31YWG.upload_empty__2KV41.upload_disabled__cQG7K:hover,
.upload_uploader__31YWG.upload_empty__2KV41.upload_disabled__cQG7K:focus,
.upload_uploader__31YWG.upload_empty__2KV41.upload_disabled__cQG7K:active {
  cursor: not-allowed;
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
  box-shadow: none;
}

.upload_uploader__31YWG.upload_empty__2KV41 {
  color: var(--snow);
  display: flex;
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
}

.upload_uploader__31YWG.upload_empty__2KV41:hover {
  background: var(--dark-game-blue);
  border: 1px solid var(--dark-game-blue);
}

.upload_uploader__31YWG.upload_empty__2KV41[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
}

.upload_uploader__31YWG.upload_empty__2KV41:focus,
.upload_uploader__31YWG.upload_empty__2KV41:active {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  box-shadow: var(--box-shadow);
}

.upload_uploaderButtonBody__3cyvZ {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: 10px;
  text-align: center;
}

.upload_uploaderButtonIcon__3TCEz {
  font-size: 16px;
  color: inherit;
  margin-bottom: var(--input-padding);
}

.upload_inputFile__23znT {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.upload_inputFileBtn__3ZQXX {
  position: relative !important;
  cursor: pointer !important;
}

.upload_uploader__31YWG .upload_img__1VVr2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  background-image: url(https://configurator.adact.brocoders.xyz/static/media/pngbackground.1ebf12dd.png);
  background-size: 100px 100px;
}

.upload_dndArea__1G19z {
  width: 560px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 180px;
  border-radius: 10px;
  margin: auto auto 24px auto;
  background: var(--pastel-blue);
  border: 2px dashed var(--light-blue);
}

.upload_modalWrapper__ch0GI {
}

.upload_dndArea__1G19z.upload_canDrop__3mMsF {
  border: 1px solid var(--green);
  background: var(--green-opacity);
}

.upload_iconWrapper__3G4Re {
  font-size: 32px;
  color: var(--game-blue);
}

.upload_sidesWrapper__1aTZH {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.upload_sidesWrapper__1aTZH + .upload_footerWrapper__2clOq {
  margin-top: 24px;
}

.upload_imageWrapper__35q1j {
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.upload_imagePreview__9Qc_R {
  max-height: 600px;
  max-width: 100%;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.upload_cropWrapper__2Jei6 {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.upload_cropBox__3Clhk {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px dashed var(--snow);
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.4);
}

.upload_cropPoint__27JtZ {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid var(--border-gray);
  margin-top: -5px;
  margin-left: -5px;
}

.upload_backgroundEditorWrapper__2s13M {
  width: 100%;
  display: flex;
}

.upload_editorCanvas__1XvLA {
  background-image: url(https://configurator.adact.brocoders.xyz/static/media/pngbackground.1ebf12dd.png);
  background-size: 100px 100px;
}

.upload_leftSide__2dO5F {
  display: flex;
  justify-content: center;
  max-width: 600px;
  width: 100%;
}

.upload_rightSide__2FfUK {
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upload_imageSizesWrapper__34liO {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.upload_chainIcon__3KD9u {
  font-size: 18px;
  color: var(--gray-font);
  position: absolute;
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.upload_removableWrapper__yr6rT {
  position: relative;
}

.upload_removableWrapper__yr6rT:hover .upload_buttonsImageWrapper__4OpAT {
  display: flex;
}

.upload_buttonsImageWrapper__4OpAT button {
  margin-bottom: var(--input-padding);
  width: 100%;
}
.upload_buttonsImageWrapper__4OpAT button:last-child {
  margin-bottom: 0;
}

.upload_buttonsImageWrapper__4OpAT {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
}

.upload_btnsWrapper__3eKZ7 {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload_footerBtn__2x8YJ {
  margin-left: 5px;
}

.upload_btnsWrapper__3eKZ7 button + button {
  margin-left: 5px;
}

.upload_arrow__nNOWO {
  margin-right: 5px;
}

.upload_btnArrow__MONs4 {
  height: 30px !important;
  width: 50% !important;
  border: none !important;
  font-size: 14px !important;
  box-shadow: none !important;
  background: none !important;
  color: var(--game-blue) !important;
}

.upload_btnArrow__MONs4 span {
  border-bottom: 1px solid var(--game-blue) !important;
}

.upload_applyBtn__26Vkk {
  max-height: 40px !important;
  height: 40px !important;
  width: 50% !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

.upload_canvasWrapper__2VEIl {
  margin: auto;
}

.upload_modalConfirmText__2YSQJ {
  margin-bottom: 8px;
}

.upload_addyThink__qqUoR {
  background: url(https://configurator.adact.brocoders.xyz/static/media/addythink.b59d5863.png) center top no-repeat;
  background-size: cover;
  width: 94px;
  height: 177px;
}

.upload_busyWrapper__No1Ew {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload_busyWrapper__No1Ew .upload_hint__38kka {
  margin-top: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
}

.icon_icon__3iAWn {
  display: flex;
  width: 24px;
}

.icon_icon__3iAWn.icon_clickable__1P0q- {
  cursor: pointer;
}

.spinner_wrapper__3dV28 {
  color: var(--game-blue);
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  flex-direction: column;
}

.spinner_text__1Kc-d {
  align-items: center;
  margin-top: 8px;
  z-index: 1;
}

.spinner_white__1J76J {
  width: 36px;
  height: 36px;
  -webkit-animation: spinner_spin__O7Dy2 1s cubic-bezier(0.67, 0.56, 0.06, 0.765) infinite;
  animation: spinner_spin__O7Dy2 1s cubic-bezier(0.67, 0.56, 0.06, 0.765) infinite;
  align-items: center;
  justify-content: center;
}

.spinner_white__1J76J img {
  width: 36px;
  height: 36px;
}

.spinner_overlay__1fCor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}
@-webkit-keyframes spinner_spin__O7Dy2 {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner_spin__O7Dy2 {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.button_button__13Yyo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  border-radius: 40px;
  border: 1px solid transparent;
}

.button_button__13Yyo.button_md__Ugfoj {
  padding: 7px 16px;
  box-shadow: var(--btn-shadow);
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  max-height: 32px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 16px;
  min-width: 106px;
}

.button_button__13Yyo.button_xs__rbIRU {
  padding: 4px 16px;
  max-height: 26px;
  box-shadow: var(--btn-shadow);
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  min-width: 106px;
}

.button_button__13Yyo.button_xxs__1dMK9 {
  padding: 4px 12px;
  max-height: 24px;
  box-shadow: var(--btn-shadow);
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  min-width: 74px;
}

.button_button__13Yyo.button_lg__eVG0V {
  padding: 12px 16px;
  box-shadow: var(--btn-shadow);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 17px;
  max-height: 40px;
  font-weight: 500;
  letter-spacing: -0.5px;
  min-width: 106px;
}

.button_button__13Yyo.button_lg__eVG0V.button_onlyIcon__39eBX,
.button_button__13Yyo.button_md__Ugfoj.button_onlyIcon__39eBX,
.button_button__13Yyo.button_xs__rbIRU.button_onlyIcon__39eBX {
  min-width: auto;
}

.button_icon__32-ZZ {
  margin-right: 6px;
}

.button_backIcon__2KR4_ {
  margin-left: 6px;
}

.button_button__13Yyo.button_onlyIcon__39eBX .button_icon__32-ZZ {
  margin-right: 0;
}

.button_spinner__2asKH {
  -webkit-animation: button_spin__3lzqS 1s cubic-bezier(0.67, 0.56, 0.06, 0.765) infinite;
  animation: button_spin__3lzqS 1s cubic-bezier(0.67, 0.56, 0.06, 0.765) infinite;
  align-items: center;
  justify-content: center;
}

.button_button__13Yyo.button_blue__3lV3G {
  background: var(--game-blue);
  color: var(--snow);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--game-blue);
}

.button_button__13Yyo.button_blue__3lV3G:hover:not(.button_loading__CfxW6) {
  background: var(--game-blue-hover);
  border: 1px solid var(--game-blue-hover);
}

.button_button__13Yyo.button_blue__3lV3G:active:not(.button_loading__CfxW6) {
  background: var(--game-blue-active);
  border: 1px solid var(--game-blue-active);
}

.button_button__13Yyo.button_yellow__4u4q0 {
  background: var(--new-yellow);
  color: var(--snow);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--new-yellow);
}

.button_button__13Yyo.button_yellow__4u4q0:hover:not(.button_loading__CfxW6) {
  background: var(--new-yellow-hover);
  border: 1px solid var(--new-yellow-hover);
}

.button_button__13Yyo.button_yellow__4u4q0:active:not(.button_loading__CfxW6) {
  background: var(--new-yellow-active);
  border: 1px solid var(--new-yellow-active);
}

.button_button__13Yyo.button_yellow-ghost__2Hy3b {
  color: var(--new-yellow);
  background: var(--snow);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--new-yellow);
}

.button_button__13Yyo.button_yellow-ghost__2Hy3b:hover:not(.button_loading__CfxW6) {
  background: var(--new-yellow-hover);
  border: 1px solid var(--new-yellow-hover);
}

.button_button__13Yyo.button_yellow-ghost__2Hy3b:active:not(.button_loading__CfxW6) {
  background: var(--new-yellow-active);
  color: var(--snow);
  border: 1px solid var(--new-yellow-active);
}

.button_loading__CfxW6 {
  cursor: progress;
}

.button_button__13Yyo.button_ghost__3sR15 {
  background: var(--snow);
  color: var(--night);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--new-light-gray);
}

.button_button__13Yyo.button_ghost__3sR15:hover:not(.button_loading__CfxW6) {
  background: var(--snow);
  color: var(--new-gray-500);
  border: 1px solid var(--new-light-gray);
}

.button_button__13Yyo.button_ghost__3sR15:active:not(.button_loading__CfxW6) {
  color: var(--night);
  background: var(--new-light-gray-3);
  border: 1px solid var(--new-light-gray);
}

.button_button__13Yyo.button_blue-ghost__3LqgI,
.button_button__13Yyo.button_bluer-ghost__YvHSj {
  background: var(--snow);
  color: var(--game-blue);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--new-light-gray);
}

.button_button__13Yyo.button_blue-ghost__3LqgI:hover:not(.button_loading__CfxW6) {
  background: var(--snow);
  color: var(--new-gray-500);
  border: 1px solid var(--new-light-gray);
}

.button_button__13Yyo.button_blue-ghost__3LqgI:active:not(.button_loading__CfxW6) {
  color: var(--game-blue);
  background: var(--new-light-gray-3);
  border: 1px solid var(--new-light-gray);
}

.button_button__13Yyo.button_bluer-ghost__YvHSj {
  border: 1px solid var(--game-blue);
}

.button_button__13Yyo.button_bluer-ghost__YvHSj:hover:not(.button_loading__CfxW6) {
  background: var(--snow);
  color: var(--new-gray-500);
  border: 1px solid var(--new-light-gray);
}

.button_button__13Yyo.button_bluer-ghost__YvHSj:active:not(.button_loading__CfxW6) {
  color: var(--game-blue);
  background: var(--new-light-gray-3);
  border: 1px solid var(--new-light-gray);
}

.button_button__13Yyo.button_link__3C8ov {
  background: transparent;
  color: var(--new-blue);
  box-shadow: none;
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid transparent;
  text-decoration: underline;
  box-sizing: content-box;
}

.button_button__13Yyo.button_link__3C8ov:hover:not(.button_loading__CfxW6) {
  background: transparent;
  color: var(--new-blue);
  border: 1px solid transparent;
}

.button_button__13Yyo.button_link__3C8ov:active:not(.button_loading__CfxW6) {
  color: var(--new-blue);
  background: transparent;
  border: 1px solid transparent;
}

.button_button__13Yyo[disabled],
.button_button__13Yyo[disabled]:hover,
.button_button__13Yyo[disabled]:active,
.button_button__13Yyo.button_blue__3lV3G[disabled],
.button_button__13Yyo.button_blue__3lV3G[disabled]:hover,
.button_button__13Yyo.button_blue__3lV3G[disabled]:active,
.button_button__13Yyo.button_yellow__4u4q0[disabled],
.button_button__13Yyo.button_yellow__4u4q0[disabled]:hover,
.button_button__13Yyo.button_yellow__4u4q0[disabled]:active,
.button_button__13Yyo.button_ghost__3sR15[disabled],
.button_button__13Yyo.button_ghost__3sR15[disabled]:hover,
.button_button__13Yyo.button_ghost__3sR15[disabled]:active {
  background: var(--new-gray-4);
  cursor: not-allowed;
  border: 1px solid var(--new-gray-4);
  color: var(--new-gray-5);
  box-shadow: none;
}
.button_button__13Yyo.button_link__3C8ov[disabled],
.button_button__13Yyo.button_link__3C8ov[disabled]:hover,
.button_button__13Yyo.button_link__3C8ov[disabled]:active {
  background: transparent;
  cursor: not-allowed;
  border: 1px solid transparent;
  color: var(--new-gray-5);
  box-shadow: none;
}
@-webkit-keyframes button_spin__3lzqS {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes button_spin__3lzqS {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.upload_inputFile__19sVU {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.upload_inputFileBtn__2s1hi {
  position: relative !important;
  cursor: pointer !important;
}

.upload_dndArea__1NCti {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 180px;
  border-radius: 10px;
  background: var(--gray-file-upload);
  border: 1px dashed var(--gray-font);
}

.upload_modalWrapper__1EcY6 {
}

.upload_dndArea__1NCti.upload_canDrop__1mQCA {
  border: 1px solid var(--green);
  background: var(--green-opacity);
}

.upload_iconWrapper__1uSkb {
  font-size: 48px;
  color: var(--green);
}

.upload_iconFileWrapper__1XzCf {
  font-size: 48px;
  color: var(--game-blue);
}

.upload_fileName__2Xxkp {
  font-size: var(--input-fsz);
  text-align: center;
  margin-bottom: var(--input-padding);
  color: var(--cobalt);
}

.upload_withFileWrapper__-BfnU {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.upload_uploaderButton__1_WEj {
  margin-bottom: var(--input-padding);
  height: var(--input-height);
  max-width: 180px;
}

.upload_valueText__2RA-P {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select_select__3pPYi {
  width: 100%;
}

.select_wrapper__3qvxL {
  position: relative;
  width: 100%;
}

.select_withIcon__2FeJM {
  padding-left: 25px !important;
}

.select_select__3pPYi .ant-select-selector {
  height: var(--input-height) !important;
  font-size: var(--input-fsz);
  color: var(--black);
  background: var(--snow);
  border-radius: 8px !important;
  outline: none;
  border: 1px solid var(--gray-input-preview) !important;
}

.select_select__3pPYi .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.select_select__3pPYi .ant-select-selector:active {
  border: 1px solid var(--cobalt);
}

.select_select__3pPYi .ant-select-selector:hover {
  border: 1px solid var(--game-blue);
}

.select_select__3pPYi .ant-select-selector:focus {
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
  background: var(--snow);
  color: var(--cobalt);
}

.select_withIcon__2FeJM {
  padding-left: 25px !important;
}

.select_icon__25wnp {
  font-size: var(--input-fsz);
  color: var(--cobalt);
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 7px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.select_newForm__194w_.select_withIcon__2FeJM {
  padding-left: 36px !important;
}

.select_icon__25wnp.select_newIcon__14twh {
  font-size: 16px !important;
  color: var(--gray-placeholder) !important;
  top: 0;
  z-index: 1;
  left: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: none;
          transform: none;
}

.select_select__3pPYi.select_newForm__194w_ .ant-select-selector {
  background: var(--snow);
  height: 50px !important;
  border-radius: 5px !important;
  outline: none !important;
  padding: 12px 16px !important;
  border: none !important;
  background: #f8f8f8 !important;
  font-size: 16px !important;
  font-weight: 400;
  color: #828b9a !important;
}

.select_select__3pPYi.select_newForm__194w_ .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.select_select__3pPYi.select_newForm__194w_ .ant-select-selector:active {
  border: none !important;
}

.select_select__3pPYi.select_newForm__194w_ .ant-select-selector:hover {
  border: none !important;
}

.select_select__3pPYi.select_newForm__194w_ .ant-select-selector:focus {
  border: none !important;
  box-shadow: var(--box-shadow) !important;
  background: var(--snow) !important;
  color: var(--cobalt) !important;
}

.colorInput_wrapper__4Fy1t {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--input-padding);
  min-width: 110px;
}

.colorInput_inputWrapper__EY3MF {
  width: 110px;
  display: flex;
  position: relative;
}

.colorInput_valueInput__1SMjY {
  box-shadow: none;
  border-radius: 8px;
  width: 100%;
  padding: 2px 10px 2px 26px;
  align-items: center;
  position: relative;
  background: var(--snow);
  color: var(--cobalt);
  font-size: var(--input-fsz);
  height: var(--input-height);
  outline: none;
  border: 1px solid var(--gray-input-preview);
}

.colorInput_valueInput__1SMjY[disabled],
.colorInput_valueInput__1SMjY[disabled]:hover {
  border: 1px solid var(--border-gray);
  background: var(--gray-file-upload);
  color: var(--light-gray-font);
}

.colorInput_valueInput__1SMjY:active {
  border: 1px solid var(--cobalt);
}

.colorInput_valueInput__1SMjY:hover {
  border: 1px solid var(--game-blue);
}

.colorInput_valueInput__1SMjY:focus {
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
  background: var(--snow);
  color: var(--cobalt);
}

.colorInput_inputWrapper__EY3MF .colorInput_colorInput__2R2uu {
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  margin-right: 10px;
  opacity: 0;
  position: absolute;
}

.colorInput_inputWrapper__EY3MF .colorInput_colorInput__2R2uu[disabled] {
  opacity: 0 !important;
}

.colorInput_color__3TXcl {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 8px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 1px solid var(--border-gray);
}

.colorInput_color__3TXcl.colorInput_transparent__1Dwlh:after {
  width: 1px;
  height: 100%;
  background: var(--red);
  top: 0;
  display: inline-block;
  left: 50%;
  content: '';
  -webkit-transform: rotateZ(45deg) translateX(-50%);
          transform: rotateZ(45deg) translateX(-50%);
  position: absolute;
}

.colorInput_clearBtn__2q3Vn {
  border: none;
  background: none;
  position: absolute;
  right: 3px;
  top: 50%;
  font-size: 9px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  line-height: 9px;
  cursor: pointer;
  padding: 5px;
  color: var(--black);
  outline: none;
}

.input_slider__1Qhms {
  width: 100%;
}

.input_slider__1Qhms .ant-slider-track,
.input_slider__1Qhms:hover .ant-slider-track {
  background: var(--game-blue) !important;
}
.input_slider__1Qhms .ant-slider-handle,
.input_slider__1Qhms:hover .ant-slider-handle {
  border: 2px solid var(--game-blue) !important;
}

.input_sliderWrapper__1baaN {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: var(--input-height);
}

.input_sliderValue__2v1jl {
  width: 35px;
  min-width: 35px;
  margin-left: 8px;
  font-size: var(--input-fsz);
  color: var(--black);
}

.input_sliderValue__2v1jl.input_disabled__2xhT9 {
  color: var(--light-gray-font);
}

.input_slider__1Qhms.input_disabled__2xhT9 .ant-slider-track,
.input_slider__1Qhms.input_disabled__2xhT9:hover .ant-slider-track {
  background: var(--blue-line) !important;
}
.input_slider__1Qhms.input_disabled__2xhT9 .ant-slider-handle,
.input_slider__1Qhms.input_disabled__2xhT9:hover .ant-slider-handle {
  border: 2px solid var(--blue-line) !important;
}

.input_wrapper__10ONu {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input_label__38cno {
  color: var(--gray);
  margin-bottom: 5px;
}

.input_errors__3MSL7 {
  display: flex;
  flex-direction: column;
}

.input_error__1c2XD {
  color: #ff4d4f;
  font-size: 12px;
  margin-bottom: 5px;
}

.input_radioWrapper__1xKBD {
  display: flex;
  flex-direction: row;
}

.input_radio__32GbA {
  height: var(--input-height);
  width: var(--input-height);
  background: var(--snow);
  border: 1px solid var(--border-gray);
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  cursor: pointer;
}

.input_radio__32GbA.input_textRadio__1B3Qx {
  width: auto;
  font-size: 12px;
}

.input_radio__32GbA[disabled] {
  cursor: not-allowed;
  border: 1px solid var(--border-gray);
  background: var(--gray-file-upload);
}

.input_radio__32GbA.input_active__2HMKW[disabled] {
  border: 1px solid var(--blue-line);
  background: var(--blue-line);
}

.input_radio__32GbA:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.input_radio__32GbA:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.input_radio__32GbA.input_active__2HMKW {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  color: var(--snow);
}

.radio_wrapper__2fdpF {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--input-padding);
}

.radio_label__2VEI8 {
  color: var(--gray);
  margin-bottom: 5px;
}

.radio_radio__2D28_ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radio_label__2VEI8 {
  margin: 0;
  color: var(--black);
}

.checkbox_input__E9KhT {
  white-space: nowrap;
}

.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--game-blue);
  border-color: var(--game-blue);
}

.ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--game-blue) !important;
}

.ant-checkbox-checked::after {
  border: 1px solid var(--game-blue) !important;
}

.ant-checkbox-inner {
  border-radius: 3px !important;
}

.ant-checkbox, .ant-checkbox-wrapper {
  font-size: 14px !important;
}

@media all and (max-width: 1599px) {
  .ant-checkbox, .ant-checkbox-wrapper {
    font-size: 12px !important;
  }
}

.tags_wrapper__zxgjV {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tags_inputs__wCjmT {
  display: flex;
  margin-bottom: var(--input-padding);
}

.tags_input__6wCKu {
  margin-right: 8px;
  margin-bottom: 0;
}

.tags_tags__2p50P {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.tags_tag__1DvQB {
  padding: 1px 16px 1px 9px;
  background: var(--snow);
  position: relative;
  border: 1px solid var(--border-gray);
  border-radius: 20px;
  font-size: var(--input-fsz);
  color: var(--black);
  margin: 0 var(--input-padding) var(--input-padding) 0;
}

.tags_removeTag__1A7g9 {
  color: var(--dark-gray);
  font-size: 10px;
  position: absolute;
  top: 50%;
  right: 2px;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  padding: 0;
}

.tags_tag__1DvQB:last-child {
  margin-right: 0;
}

.tags_addBtn__qmYoc {
  height: var(--input-height) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner:hover {
  color: var(--cobalt) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--gray-input-preview) !important;
  border-radius: 6px !important;
}

.ant-picker-cell-in-view .ant-picker-cell-inner {
  color: var(--cobalt) !important;
  border-radius: 6px !important;
  border: 1px solid transparent !important;
}

.ant-picker-cell-in-view .ant-picker-cell-inner:hover {
  background: none !important;
  border: 1px solid var(--game-blue) !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(
    .ant-picker-cell-range-end
  ):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background: none !important;
}

.ant-picker-dropdown .ant-picker-footer .ant-btn-primary {
  border-radius: 8px !important;
  background: var(--game-blue) !important;
  border: 1px solid var(--game-blue) !important;
  height: 16px !important;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

.ant-picker-dropdown .ant-picker-footer .ant-btn-primary[disabled] {
  background: var(--blue-line) !important;
  border: 1px solid var(--blue-line) !important;
  color: var(--snow) !important;
}

.ant-picker-dropdown .ant-picker-footer .ant-picker-now-btn {
  color: var(--game-blue) !important;
}

.tox-tinymce {
  border: none !important;
  border-radius: 0 !important;
}

.rte_rte__qPXxk {
  width: 100%;
}

.tox .tox-toolbar__group {
  height: var(--rte-btn-height) !important;
  margin-right: 6px !important;
  margin-bottom: 6px !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 0 !important;
  box-sizing: content-box;
}

.tox .tox-split-button {
  height: var(--rte-btn-height) !important;
  margin: 0 !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 0 !important;
  box-sizing: content-box;
}

.tox .tox-toolbar__group:last-child {
  margin-right: 0 !important;
}

.tox .tox-tbtn {
  height: 26px !important;
  margin: 0 !important;
  border-radius: 0 !important;
  border-top: 1px solid var(--gray-input-preview) !important;
  border-bottom: 1px solid var(--gray-input-preview) !important;
  border-right: 1px solid var(--gray-input-preview) !important;
  padding: 0 !important;
}

.tox .tox-tbtn:first-child {
  border-radius: 8px 0 0 8px !important;
  border: 1px solid var(--gray-input-preview) !important;
}
.tox .tox-tbtn:last-child {
  border-radius: 0 8px 8px 0 !important;
  border: 1px solid var(--gray-input-preview) !important;
  border-left: none !important;
}

.tox .tox-tbtn:only-child {
  border-radius: 8px !important;
  border: 1px solid var(--gray-input-preview) !important;
}

.tox .tox-split-button__chevron {
  border-radius: 0 8px 8px 0 !important;
}

.tox .tox-tbtn:hover, .tox .tox-tbtn:focus {
  background: none !important;
  color: var(--game-blue) !important;
}

.tox .tox-tbtn--enabled, .tox .tox-tbtn--enabled:hover {
  background: var(--game-blue) !important;
}

.tox .tox-tbtn:hover {
  background: var(--game-blue) !important;
}
.tox .tox-tbtn:hover svg {
  fill: var(--snow) !important;
}
.tox .tox-tbtn--enabled svg, .tox .tox-tbtn--enabled:hover svg {
  fill: var(--snow) !important;
}
.tox .tox-tbtn--bespoke:hover .tox-tbtn__select-label {
  color: var(--snow) !important;
}

.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
  background-image: none !important;
}

.tox .tox-sidebar-wrap {
  border: 1px solid var(--gray-input-preview) !important;
  border-radius: 10px 10px 0 0 !important;
}

.tox .tox-split-button .tox-tbtn, .tox .tox-split-button {
  height: var(--rte-btn-height) !important;
}

.tox .tox-edit-area {
  border-radius: 10px 10px 0 0 !important;
}

.tox .tox-statusbar {
  border: 1px solid var(--gray-input-preview) !important;
  border-top: none !important;
  border-radius: 0 0 10px 10px !important;
}

.tox .tox-dialog {
  border-radius: 8px !important;
  border: none !important;
}

.tox .tox-dialog .tox-dialog__footer {
  border: none;
}

.tox .tox-dialog .tox-dialog__footer-end .tox-button {
  border-radius: 15px !important;
  background: var(--game-blue);
}

.tox .tox-dialog .tox-dialog__footer-end .tox-button.tox-button--secondary {
  border-radius: 15px !important;
  background: var(--snow);
  color: var(--game-blue);
  border-color: var(--game-blue);
}

.fonts_wrapper__1EKic {
  position: relative;
  width: 130px;
  height: var(--input-height);
}

.fonts_value__2fF72 {
  background: var(--snow);
  border-radius: 8px;
  color: var(--cobalt);
  height: var(--input-height);
  font-size: var(--input-fsz);
  padding: 4px 20px 4px 8px;
  border: 1px solid var(--gray-input-preview);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.fonts_valueText__1kn48 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fonts_chevron__NSxcH {
  position: absolute;
  font-size: 9px;
  color: var(--cobalt);
  top: 50%;
  right: 8px;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}

.fonts_dropdown__2FTQ0 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: var(--snow);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(119, 119, 119, 0.25);
}

.fonts_inputWrapper__2cngI {
  position: relative;
  border-bottom: 1px solid var(--gray-file-upload);
}

.fonts_inputWrapper__2cngI input {
  width: 100%;
  padding: 4px 8px 4px 30px;
  color: var(--cobalt);
  font-size: var(--input-fsz);
  border: none;
  outline: none;
  border-radius: 10px 10px 0 0;
}

.fonts_inputWrapper__2cngI .fonts_inputIcon__qNjsU {
  position: absolute;
  font-size: 12px;
  color: var(--cobalt);
  top: 50%;
  left: 15px;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}

.fonts_value__2fF72.fonts_disabled__Ba6gL {
  border: 1px solid var(--border-gray);
  background: var(--gray-file-upload);
  color: var(--light-gray-font);
  cursor: not-allowed;
}

.fonts_listWrapper__28957 {
  display: flex;
  flex-direction: column;
  max-height: 290px;
  overflow-y: auto;
}

.fonts_section__3ZzQq {
  display: flex;
  flex-direction: column;
}

.fonts_label__35oOv {
  padding: 9px 8px;
  font-size: var(--input-fsz);
  color: var(--cobalt);
  font-weight: bold;
  border-bottom: 1px solid var(--gray-file-upload);
}

.fonts_list__3YPuu {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.fonts_list__3YPuu li {
  color: var(--cobalt);
  cursor: pointer;
  width: 100%;
  padding: 9px 8px 9px 16px;
  border-bottom: 1px solid var(--gray-file-upload);
}

.fonts_list__3YPuu li:hover {
  background: var(--gray-file-upload);
}

.autocomplete_wrapper__3a_WK {
  position: relative;
  width: 130px;
  height: var(--input-height);
}

.autocomplete_value__1po-9 {
  background: var(--snow);
  border-radius: 8px;
  color: var(--cobalt);
  height: var(--input-height);
  font-size: var(--input-fsz);
  padding: 4px 20px 4px 8px;
  border: 1px solid var(--gray-input-preview);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.autocomplete_valueText__3UYn2 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete_dropdown__QiPmq {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: var(--snow);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(119, 119, 119, 0.25);
}

.autocomplete_inputWrapper__1RRH0 {
  position: relative;
  border-bottom: 1px solid var(--gray-file-upload);
}

.autocomplete_listPlaceholder__29h0J {
  border-radius: 0 0 10px 10px;
}

.autocomplete_inputWrapper__1RRH0 input {
  width: 100%;
  padding: 4px 8px 4px 30px;
  color: var(--cobalt);
  font-size: var(--input-fsz);
  border: none;
  outline: none;
  border-radius: 10px 10px 0 0;
}

.autocomplete_wrapper__3a_WK .autocomplete_clear__1tubY {
  position: absolute;
  font-size: 12px;
  color: var(--cobalt);
  z-index: 1;
  top: 50%;
  cursor: pointer;
  right: 0;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}
.autocomplete_inputWrapper__1RRH0 .autocomplete_inputIcon__eCfKm {
  position: absolute;
  font-size: 12px;
  color: var(--cobalt);
  top: 50%;
  left: 15px;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}

.autocomplete_value__1po-9.autocomplete_disabled__3EAwe {
  border: 1px solid var(--border-gray);
  background: var(--gray-file-upload);
  color: var(--light-gray-font);
  cursor: not-allowed;
}

.autocomplete_listWrapper__2c1T- {
  display: flex;
  flex-direction: column;
  max-height: 290px;
  overflow-y: auto;
}

.autocomplete_section__17oze {
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: left;
}

.autocomplete_section__17oze:last-child {
  border-radius: 0 0 10px 10px;
}

.autocomplete_withIcon__32U2U {
  margin-left: 4px;
}

.autocomplete_label__1X7I7 {
  padding: 9px 8px;
  font-size: var(--input-fsz);
  color: var(--cobalt);
  font-weight: bold;
  border-bottom: 1px solid var(--gray-file-upload);
}

.autocomplete_list__KKYCk {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.autocomplete_list__KKYCk li {
  color: var(--cobalt);
  cursor: pointer;
  width: 100%;
  padding: 9px 8px 9px 16px;
  border-bottom: 1px solid var(--gray-file-upload);
}

.autocomplete_list__KKYCk li:hover {
  background: var(--gray-file-upload);
}

.autocomplete_newForm__2tEvw {
  width: 100%;
}

.autocomplete_newFormInput__3P-t7 {
  height: 50px;
}

.autocomplete_newFormSelect__24asY {
  border: none;
  padding: 12px 16px;
  border-radius: 5px;
  height: 50px;
  background: #f8f8f8;
  color: #828b9a;
  font-size: 16px;
}

.hint_hint__3oPhb {
  display: flex;
  margin-bottom: 14px;
  align-items: center;
}

.hint_iconWrapper__1baB2 {
  color: var(--game-yellow);
  margin-right: 6px;
  font-size: 14px;
}

.hint_icon__6kKJy {
  margin-right: 12px;
}

.hint_whiteHint__352Jj {
  display: flex;
  align-items: center;
}

.hint_label__1-0dl {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--new-dark-gray);
  font-weight: 400;
  line-height: 17px;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.5px;
}

.hint_whiteHint__352Jj.hint_black__J7Gg5 .hint_label__1-0dl {
  color: var(--night);
}

.hint_iconWrapper__1baB2.hint_red__30PlE {
  color: var(--red);
}

@media all and (max-width: 1599px) {
  .hint_iconWrapper__1baB2 {
    font-size: 10px;
  }
}

.modal_overlay__1kLsr {
  position: fixed;
  top: 0;
  z-index: 12;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 49, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 20px;
}

.modal_content__1tQ-X {
  position: relative;
  display: flex;
  width: 550px;
  background: var(--snow);
  box-shadow:
    0 16px 22px rgba(0, 0, 0, 0.1),
    0 34px 55px rgba(0, 0, 0, 0.05),
    0 13px 66px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 36px 0;
  flex-direction: column;
}

.modal_hint__1HvKX {
  padding: 0 25px;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.modal_content__1tQ-X.modal_wide__2JCK5 {
  width: 800px;
}

.modal_content__1tQ-X.modal_flexible__1dRVB {
  width: auto;
  max-width: 90vw;
}

.modal_title__1efUU {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 4px;
  padding-right: 30px;
  padding-left: 25px;
}

.modal_description__2L8Ig {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--gray-500);
  margin-bottom: 6px;
  padding-right: 30px;
  padding-left: 25px;
}

.modal_contentWrapper__3ZPwa {
  margin-top: 16px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 0 25px;
}

.modal_cancelIcon__3q452 {
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  z-index: 2;
}

.modal_cancelIcon__3q452.modal_withoutTitle__3p37H {
  top: 12px;
  right: 12px;
}

.upload_uploader__3VBxU {
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 1px solid var(--border-gray);
  display: inline-block;
}

.upload_uploader__3VBxU.upload_disabled__AQj0G,
.upload_uploader__3VBxU.upload_empty__2Qcmz.upload_disabled__AQj0G,
.upload_uploader__3VBxU.upload_empty__2Qcmz.upload_disabled__AQj0G:hover,
.upload_uploader__3VBxU.upload_empty__2Qcmz.upload_disabled__AQj0G:focus,
.upload_uploader__3VBxU.upload_empty__2Qcmz.upload_disabled__AQj0G:active {
  cursor: not-allowed;
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
  box-shadow: none;
}

.upload_uploader__3VBxU.upload_empty__2Qcmz {
  color: var(--snow);
  display: flex;
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
}

.upload_modalFooter__1He7s {
  display: flex;
  margin-top: 12px;
  justify-content: flex-end;
}

.upload_modalFooter__1He7s button:not(:last-child) {
  margin-right: 8px;
}

.upload_uploader__3VBxU.upload_empty__2Qcmz:hover {
  background: var(--dark-game-blue);
  border: 1px solid var(--dark-game-blue);
}

.upload_uploader__3VBxU.upload_empty__2Qcmz[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
}

.upload_uploader__3VBxU.upload_empty__2Qcmz:focus,
.upload_uploader__3VBxU.upload_empty__2Qcmz:active {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  box-shadow: var(--box-shadow);
}

.upload_imagePreview__2dMLX {
  max-width: 300px;
  max-height: 300px;
  margin-bottom: 20px;
}

.upload_inputFile__3H7LA {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.upload_inputFileBtn__OEy57 {
  position: relative !important;
  cursor: pointer !important;
}

.upload_dndArea__2oHI7 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 180px;
  border-radius: 10px;
  background: var(--gray-file-upload);
  border: 1px dashed var(--gray-font);
}

.upload_modalWrapper__3Fz9a {
}

.upload_dndArea__2oHI7.upload_canDrop__1k2Qe {
  border: 1px solid var(--green);
  background: var(--green-opacity);
}

.upload_iconWrapper__3Sjq5 {
  font-size: 48px;
  color: var(--green);
}

.upload_iconFileWrapper__1dEU8 {
  font-size: 48px;
  color: var(--game-blue);
}

.upload_fileName__1mm00 {
  font-size: var(--input-fsz);
  text-align: center;
  margin-bottom: var(--input-padding);
  color: var(--cobalt);
}

.upload_withFileWrapper__2TSAW {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.upload_uploaderButton__5-gcg {
  margin-bottom: var(--input-padding);
  height: var(--input-height);
  max-width: 180px;
}

.upload_valueText__37Qrw {
  width: 100%;
  max-width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload_uploaderButtonBody__t_MT2 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: 10px;
  text-align: center;
}

.upload_uploaderButtonIcon__VHuxQ {
  font-size: 16px;
  color: inherit;
  margin-bottom: var(--input-padding);
}

.upload_inputFile__3H7LA {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.upload_inputFileBtn__OEy57 {
  position: relative !important;
  cursor: pointer !important;
}

.upload_uploader__3VBxU .upload_img__eD5dk {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}

.tags_wrapper__3rFFq {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tags_inputs__IUkHj {
  display: flex;
  margin-bottom: var(--input-padding);
}

.tags_input__2m8ep {
  margin-right: 8px;
  margin-bottom: 0;
}

.tags_tags__3w712 {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.tags_tag__1aUcU {
  padding: 1px 16px 1px 9px;
  background: var(--snow);
  position: relative;
  border: 1px solid var(--border-gray);
  border-radius: 20px;
  font-size: var(--input-fsz);
  color: var(--black);
  margin: 0 var(--input-padding) var(--input-padding) 0;
}

.tags_removeTag__15Jlp {
  color: var(--dark-gray);
  font-size: 10px;
  position: absolute;
  top: 50%;
  right: 2px;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  padding: 0;
}

.tags_tag__1aUcU:last-child {
  margin-right: 0;
}

.tags_addBtn__3kvam {
  height: var(--input-height) !important;
}

.input_wrapper__1YyZH {
  position: relative;
  width: 100%;
}

.input_withIcon__FfL4w {
  padding-left: 25px !important;
}

.input_icon__1udzO {
  font-size: var(--input-fsz);
  color: var(--cobalt);
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 7px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.input_newForm__2CPEQ {
  height: 50px !important;
  border-radius: 5px !important;
  outline: none !important;
  padding: 12px 16px !important;
  border: none !important;
  background: #f8f8f8 !important;
  font-size: 16px !important;
  font-weight: 400;
  color: #828b9a !important;
}

.input_newForm__2CPEQ.input_withIcon__FfL4w {
  padding-left: 36px !important;
}

.input_icon__1udzO.input_newIcon__3rQWp {
  font-size: 16px !important;
  color: var(--gray-placeholder) !important;
  top: 0;
  z-index: 1;
  left: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: none;
          transform: none;
}

.input_phoneInputWrapper__wkI77 {
  display: flex;
}
.input_phoneWrapper__2kfgV {
  margin-left: 8px;
  flex: 1 1 100%;
}

.input_select__exLT1 {
  min-width: 160px;
}

.input_wrapper__hogLH {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input_label__3mFtk {
  color: var(--gray);
  margin-bottom: 5px;
}

.input_errors__1Gejt {
  display: flex;
  flex-direction: column;
}

.input_error__1jcHI {
  color: #ff4d4f;
  font-size: 12px;
  margin-bottom: 5px;
}

.input_radioWrapper__2AVZ1 {
  display: flex;
  flex-direction: row;
}

.input_radio__SsvP8 {
  height: var(--input-height);
  width: var(--input-height);
  background: var(--snow);
  border: 1px solid var(--border-gray);
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  cursor: pointer;
}

.input_radio__SsvP8.input_textRadio__1MIfH {
  width: auto;
  font-size: 12px;
}

.input_radio__SsvP8[disabled] {
  cursor: not-allowed;
  border: 1px solid var(--border-gray);
  background: var(--gray-file-upload);
}

.input_radio__SsvP8.input_active__33lgL[disabled] {
  border: 1px solid var(--blue-line);
  background: var(--blue-line);
}

.input_radio__SsvP8:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.input_radio__SsvP8:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.input_radio__SsvP8.input_active__33lgL {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  color: var(--snow);
}

.white_wrapper__2Mi_6 {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 12px;
}

.white_wrapper__2Mi_6.white_inline__2mjUM {
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
}

.white_wrapper__2Mi_6.white_withError__1J6Z7 {
  /*animation: shake-horizontal 0.8s linear both;*/
}

.white_wrapper__2Mi_6.white_withError__1J6Z7.white_inline__2mjUM {
  display: flex;
  flex-direction: column;
}

.white_inlineBody__2L_56 {
  display: flex;
  align-items: center;
}

.white_inlineBodyWrapper__1l-EQ {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  flex-direction: column;
}

.white_fieldWrapper__r4SX0 {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}

@-webkit-keyframes white_shake-horizontal__5gCXA {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  80% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  90% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
}

@keyframes white_shake-horizontal__5gCXA {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  80% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  90% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
}

.white_errorsLabelWrapper__14TKA {
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.white_label__Sj9_Y {
  font-size: 14px;
  color: var(--night);
  font-family: 'Inter', sans-serif;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 16px;
}

.white_label__Sj9_Y.white_alignLabelRight__zHjm8 {
  text-align: right;
}

.white_wrapper__2Mi_6.white_inline__2mjUM .white_errorsLabelWrapper__14TKA {
  margin-bottom: 0;
}

.white_wrapper__2Mi_6.white_inline__2mjUM .white_label__Sj9_Y {
  margin-right: 6px;
  font-weight: 400;
}
.white_errors__2Un2T {
  color: var(--new-red);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: right;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.24px;
  display: flex;
  flex-direction: column;
}

.white_typo__seUG7 {
  height: 40px;
  display: flex;
  align-items: center;
}

.input_input__3mmIz {
  background: var(--new-gray-6);
  border: 1px solid var(--new-gray-6);
  box-shadow: none;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 16px;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.input_input__3mmIz.input_tiny__1oCt1 {
  height: 30px;
}

.input_inputWrapper__1klqn {
  position: relative;
}

.input_icon__1jfK0 {
  position: absolute;
  top: 50%;
  left: 11px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.input_postIcon__36PMy {
  position: absolute;
  top: 50%;
  right: 11px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.input_inputWrapper__1klqn .input_input__3mmIz {
  padding: 7px 16px 7px 42px;
}

.input_inputWrapper__1klqn.input_password__MHzS0 .input_input__3mmIz {
  padding: 7px 42px;
}

.input_input__3mmIz::-webkit-input-placeholder {
  color: var(--new-gray-3);
}

.input_input__3mmIz:-ms-input-placeholder {
  color: var(--new-gray-3);
}

.input_input__3mmIz::placeholder {
  color: var(--new-gray-3);
}

.input_input__3mmIz:focus {
  border: 1px solid var(--new-blue);
}

.input_input__3mmIz.input_hasError__1iky-,
.input_input__3mmIz.input_hasError__1iky-:focus {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.input_input__3mmIz[disabled] {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.input_input__3mmIz.input_readOnly__2JO6c {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
}

.input_input__3mmIz[disabled]::-webkit-input-placeholder, .input_input__3mmIz.input_readOnly__2JO6c::-webkit-input-placeholder {
  color: var(--new-gray-5);
}

.input_input__3mmIz[disabled]:-ms-input-placeholder, .input_input__3mmIz.input_readOnly__2JO6c:-ms-input-placeholder {
  color: var(--new-gray-5);
}

.input_input__3mmIz[disabled]::placeholder,
.input_input__3mmIz.input_readOnly__2JO6c::placeholder {
  color: var(--new-gray-5);
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.input_select__2kn08 {
  position: relative;
  width: 100%;
}

.input_listWrapper__2cC39 {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  width: 100%;
  z-index: 3;
  -webkit-filter: var(--filter-shadow);
          filter: var(--filter-shadow);
  background: var(--snow);
  border: 1px solid var(--new-light-gray-2);
  border-radius: 5px;
}

.input_top__3mtHI {
  top: auto;
  bottom: 100%;
}

.input_select__2kn08.input_active__3iKr6 .input_listWrapper__2cC39 {
  display: flex;
}

.input_select__2kn08.input_active__3iKr6 .input_arrow__LRIY6 {
  -webkit-transform: rotateZ(-180deg);
          transform: rotateZ(-180deg);
}

.input_listWrapper__2cC39 ul {
  padding: 15px 6px;
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 0;
}

.input_listWrapper__2cC39 ul li {
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: var(--night);
  margin-bottom: 6px;
  font-family: 'Inter', sans-serif;
}
.input_listWrapper__2cC39 ul li:hover {
  color: var(--snow);
  background: var(--game-blue);
}

.input_toggler__GjgDD {
  overflow: hidden;
  border: 1px solid var(--new-gray-6);
  background: var(--new-gray-6);
  box-shadow: none;
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 32px 7px 16px;
  display: flex;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.input_toggler__GjgDD.input_tiny__2t0Z5 {
  line-height: 1;
  height: 30px;
}

.input_arrow__LRIY6 {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 10px;
  height: 7px;
  margin-top: -3.5px;
  pointer-events: none;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.input_placeholder__HGY1w {
  color: var(--new-gray-3);
  overflow: hidden;
  word-break: break-all;
  width: 100%;
}

.input_value__yLtU7 {
  width: 100%;
  overflow: hidden;
  word-break: break-all;
}

.input_select__2kn08.input_active__3iKr6 .input_toggler__GjgDD {
  border: 1px solid var(--new-blue);
}

.input_toggler__GjgDD.input_hasError__36IAY,
.input_select__2kn08.input_active__3iKr6 .input_toggler__GjgDD.input_hasError__36IAY {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.input_toggler__GjgDD.input_disabled__1cOh0 {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.input_toggler__GjgDD.input_disabled__1cOh0 .input_placeholder__HGY1w {
  color: var(--new-gray-5);
}

.autocomplete_select__2g59B {
  position: relative;
  width: 100%;
}

.autocomplete_listWrapper__2VFzQ {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  min-width: 100%;
  z-index: 3;
  display: none;
  -webkit-filter: var(--filter-shadow);
          filter: var(--filter-shadow);
  background: var(--snow);
  border: 1px solid var(--new-light-gray-2);
  border-radius: 5px;
}

.autocomplete_clear__23gut {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 5px;
}

.autocomplete_select__2g59B.autocomplete_active__3p22L .autocomplete_listWrapper__2VFzQ {
  display: flex;
}

.autocomplete_listWrapper__2VFzQ ul {
  padding: 15px 6px;
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 0;
}

.autocomplete_listWrapper__2VFzQ ul li {
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: var(--night);
  white-space: nowrap;
  margin-bottom: 6px;
  font-family: 'Inter', sans-serif;
}

.autocomplete_listWrapper__2VFzQ ul li:hover:not(.autocomplete_empty__1gKxD) {
  color: var(--snow);
  background: var(--game-blue);
}

.autocomplete_search__14W5y {
  border: 1px solid var(--new-gray-6);
  background: var(--new-gray-6);
  box-shadow: none;
  cursor: pointer;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 26px 7px 30px;
  align-items: center;
  display: flex;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.autocomplete_search__14W5y.autocomplete_tiny__1S7GI {
  height: 30px;
}

.autocomplete_search__14W5y.autocomplete_withoutIcon__Nbk91 {
  padding: 7px 16px;
}

.autocomplete_search__14W5y::-webkit-input-placeholder {
  color: var(--new-gray-3);
}

.autocomplete_search__14W5y:-ms-input-placeholder {
  color: var(--new-gray-3);
}

.autocomplete_search__14W5y::placeholder {
  color: var(--new-gray-3);
}

.autocomplete_searchWrapper__1-utG {
  position: relative;
}

.autocomplete_icon__3dLwi {
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 8px;
  width: 16px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 16px;
}

.autocomplete_placeholder__1LOeJ {
  color: var(--new-gray-3);
}

.autocomplete_search__14W5y:focus {
  border: 1px solid var(--new-blue);
}

.autocomplete_search__14W5y.autocomplete_hasError__3cyjG,
.autocomplete_search__14W5y.autocomplete_hasError__3cyjG:focus {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.autocomplete_search__14W5y[disabled] {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.autocomplete_search__14W5y[disabled]::-webkit-input-placeholder {
  color: var(--new-gray-5);
}

.autocomplete_search__14W5y[disabled]:-ms-input-placeholder {
  color: var(--new-gray-5);
}

.autocomplete_search__14W5y[disabled]::placeholder {
  color: var(--new-gray-5);
}

.toggler_togglerWrapper__3X6yo {
  display: flex;
  pointer-events: auto;
}

.toggler_checkbox__2O3IQ {
  display: none;
}

.toggler_spotWrapper__1Y5Mt {
  display: flex;
  width: 38px;
  height: 22px;
  border-radius: 15px;
  background: var(--new-gray-7);
  position: relative;
  transition: background var(--animation);
  cursor: pointer;
}

.toggler_spotWrapper__1Y5Mt.toggler_checkedWithText__2fcSa {
  padding-right: 23px;
  width: auto;
  padding-left: 6px;
  color: var(--snow);
}

.toggler_spotWrapper__1Y5Mt.toggler_unCheckedWithText__2tEMR {
  padding-left: 23px;
  width: auto;
  padding-right: 6px;
}

.toggler_checkbox__2O3IQ:checked + .toggler_spotWrapper__1Y5Mt {
  background: var(--game-blue);
}

.toggler_checkbox__2O3IQ:checked + .toggler_spotWrapper__1Y5Mt .toggler_spot__si4Ej {
  right: 2px;
}

.toggler_spotWrapper__1Y5Mt.toggler_badGood__3jCE9 .toggler_checkbox__2O3IQ:checked + .toggler_spotWrapper__1Y5Mt {
  background: var(--snow);
}

.toggler_spotWrapper__1Y5Mt.toggler_badGood__3jCE9.toggler_bad__3NVEZ {
  background: var(--new-red);
}

.toggler_spotWrapper__1Y5Mt.toggler_badGood__3jCE9.toggler_good__39-8q {
  background: var(--new-green-500);
}

.toggler_checkbox__2O3IQ[disabled] + .toggler_spotWrapper__1Y5Mt {
  cursor: not-allowed;
  background: var(--new-gray-4);
}

.toggler_checkbox__2O3IQ[disabled] + .toggler_spotWrapper__1Y5Mt .toggler_spot__si4Ej {
  background: var(--new-gray-5);
}

.toggler_spot__si4Ej {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--snow);
  top: 50%;
  transition:
    right var(--animation),
    background var(--animation);
  right: calc(100% - 20px);
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.toggler_wrapper__pvPKN {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  pointer-events: none;
}

.toggler_wrapper__pvPKN.toggler_sb__1JKPH {
  justify-content: space-between;
}

.toggler_errorsLabelWrapper__3TP0X {
  margin-right: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.toggler_label__1SWcU {
  font-size: 14px;
  color: var(--night);
  font-family: 'Inter', sans-serif;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 16px;
}

.toggler_errors__MX9rn {
  color: var(--new-red);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: right;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.24px;
  display: flex;
  flex-direction: column;
}

.toggler_typo__sfFyQ {
  height: 40px;
  display: flex;
  align-items: center;
}

.toggler_inline__20qWo {
  margin-bottom: 8px;
}

.toggler_alignLabelRight__2HEPF {
  text-align: right;
}

.toggler_inline__20qWo .toggler_label__1SWcU {
  margin-right: 6px;
  font-weight: 400;
}

.autocomplete_select__2taGm {
  position: relative;
}

.autocomplete_listWrapper__PMdfy {
  position: absolute;
  top: 40px;
  left: 0;
  margin-top: 4px;
  width: 100%;
  z-index: 2;
  display: none;
  -webkit-filter: var(--filter-shadow);
          filter: var(--filter-shadow);
  background: var(--snow);
  border: 1px solid var(--new-light-gray-2);
  border-radius: 5px;
}

.autocomplete_closeIcon__JYKwS {
  display: flex;
  cursor: pointer;
  margin-left: 4px;
  width: 12px;
  height: 12px;
}

.autocomplete_select__2taGm.autocomplete_active__D4VFS .autocomplete_listWrapper__PMdfy {
  display: flex;
}

.autocomplete_listWrapper__PMdfy ul {
  padding: 15px 6px;
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 0;
}

.autocomplete_listWrapper__PMdfy ul li {
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: var(--night);
  margin-bottom: 6px;
  font-family: 'Inter', sans-serif;
}

.autocomplete_listWrapper__PMdfy ul li:hover:not(.autocomplete_empty__2q2hi) {
  color: var(--snow);
  background: var(--game-blue);
}

.autocomplete_search__3vlig {
  border: 1px solid var(--new-gray-6);
  background: var(--new-gray-6);
  box-shadow: none;
  cursor: pointer;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 16px 7px 46px;
  align-items: center;
  display: flex;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.autocomplete_search__3vlig::-webkit-input-placeholder {
  color: var(--new-gray-3);
}

.autocomplete_search__3vlig:-ms-input-placeholder {
  color: var(--new-gray-3);
}

.autocomplete_search__3vlig::placeholder {
  color: var(--new-gray-3);
}

.autocomplete_searchWrapper__Mm8c3 {
  position: relative;
}

.autocomplete_icon__14r8m {
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 16px;
  width: 16px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 16px;
}

.autocomplete_placeholder__2OL3P {
  color: var(--new-gray-3);
}

.autocomplete_search__3vlig:focus {
  border: 1px solid var(--new-blue);
}

.autocomplete_search__3vlig.autocomplete_hasError__2IMKR,
.autocomplete_search__3vlig.autocomplete_hasError__2IMKR:focus {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.autocomplete_search__3vlig[disabled] {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.autocomplete_search__3vlig[disabled]::-webkit-input-placeholder {
  color: var(--new-gray-5);
}

.autocomplete_search__3vlig[disabled]:-ms-input-placeholder {
  color: var(--new-gray-5);
}

.autocomplete_search__3vlig[disabled]::placeholder {
  color: var(--new-gray-5);
}

.autocomplete_tagsWrapper__2qcjd {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.autocomplete_tagsWrapper__2qcjd.autocomplete_bottom__1_Fvn {
  margin-top: 4px;
}

.autocomplete_tag__6UXPk {
  display: flex;
  align-items: center;
  border-radius: 40px;
  /*padding: 4px 12px 4px 4px;*/
  padding: 4px 8px;
  max-height: 26px;
  white-space: nowrap;
  box-shadow: var(--btn-shadow);
  font-family: 'Inter', sans-serif;
  font-size: 9px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  justify-content: space-between;
  background: var(--game-blue);
  color: var(--snow);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--game-blue);
  margin: 0 4px 4px 0;
  position: relative;
}

.autocomplete_tag__6UXPk.autocomplete_md__3jeZM {
  font-size: 12px;
  margin: 0 6px 6px 0;
  padding: 6px 10px;
  max-height: 32px;
}

.autocomplete_tagIcon__3CcP3 img {
  width: 10px;
  height: 10px;
}

.autocomplete_tagIcon__3CcP3 {
  display: flex;
  position: absolute;
  top: 50%;
  right: 2px;
  width: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.upload_categoriesWrapper__10oyS {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.upload_categoryBtn__3zdN2 {
  margin-right: 8px;
  margin-top: 4px;
}

.upload_table__1PsPb tbody tr {
  position: relative;
  border: none !important;
}

.upload_table__1PsPb tbody td:nth-child(2) {
  min-width: 330px;
}

.upload_table__1PsPb tbody tr td {
  position: relative;
  z-index: 2;
}

.upload_table__1PsPb tbody tr::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #ffffff;
  border-bottom: 2px solid var(--gray-file-upload);
}

.upload_table__1PsPb tbody tr.upload_activeRow__29Oj7::after {
  border: 2px solid var(--game-blue) !important;
}

.upload_audioWrapper__33dcz {
  height: 40px;
  display: flex;
  align-items: center;
}
.upload_audioPlayer__3485o {
  height: 35px;
}

.upload_tableCell__2F9Ba {
  display: flex;
}

.upload_activeRow__29Oj7 {
  background: #ffffff;
}

.upload_checkmark__3UtS5 {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 16px;
  height: 16px;
}

.upload_inputFile__2uryI {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.upload_inputFileBtn__BLO8a {
  width: 150px !important;
  height: 28px !important;
}
.upload_inputFileBtn__BLO8a * {
  cursor: pointer !important;
}

.upload_inputFileBtn__BLO8a input {
  color: transparent;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.upload_inputFileBtn__BLO8a .upload_imageWrapper__9hskN {
  background: #f0f2ff;
}

.upload_previewWrapper__3iibD {
  display: flex;
  flex-direction: column;
}

.upload_footerBtns__28hq6 {
  display: flex;
  padding-top: 12px;
  justify-content: flex-end;
}

.upload_footerBtns__28hq6 button:not(:last-child) {
  margin-right: 16px;
}

.upload_modalWrapper__2NgGf {
}

.upload_dndArea__17OHd {
  background: var(--pastel-blue);
  width: 100%;
  height: 172px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 10px;
  margin-top: 10px;
  border: 2px dashed var(--light-blue);
  position: relative;
}

.upload_dndArea__17OHd p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: var(--game-blue);
  letter-spacing: -0.3px !important;
}

.upload_dndArea__17OHd.upload_canDrop__whhlz {
  background: var(--green-opacity);
}

.upload_iconWrapper__22Eod {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.upload_iconWrapper__22Eod img {
  width: 100%;
  height: 100%;
}

.upload_uploadHint__-MY-V {
  font-size: 14px !important;
  font-family: Inter, 'serif' !important;
  font-weight: 400 !important;
  letter-spacing: -0.30000001192092896px !important;
  text-align: center !important;
}
.upload_uploadHint__-MY-V + .upload_uploadHint__-MY-V {
  margin-top: -10px;
  margin-bottom: 5px;
}

.upload_iconFileWrapper__1siow {
  font-size: 48px;
  color: var(--game-blue);
}

.upload_fileName__1efwm {
  font-size: var(--input-fsz);
  text-align: center;
  margin-bottom: var(--input-padding);
  color: var(--cobalt);
}

.upload_text__2peuW {
  margin-top: 8px;
}

.upload_withFileWrapper__ifvGQ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.upload_uploaderButton__2VdSU {
  max-width: 120px;
}

.upload_valueText__3aCIL {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload_radioButton__1UKn2 {
  margin-bottom: 0 !important;
  width: 420px;
  pointer-events: none;
}

.upload_buttonsWrapper__GnzUH {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.upload_buttonsWrapper__GnzUH .upload_btn__hpnFU + .upload_btn__hpnFU {
  margin-left: 8px;
}

.upload_emptyTable__3slX4 {
  min-height: 200px !important;
}

.table_wrapper__2XkKW {
  width: 100%;
  border: none;
  color: var(--cobalt);
}

.table_grayRow__3D9A5 {
  background: var(--new-light-gray);
}

.table_wrapper__2XkKW td {
  padding: 4px 10px;
}

.table_wrapper__2XkKW tr {
  border-bottom: 1px solid var(--gray-file-upload);
}

.table_wrapper__2XkKW tbody {
  max-height: 300px;
  overflow-y: auto;
}

.table_wrapper__2XkKW.table_clear__2WUfz tr {
  border-bottom: none;
}

.table_wrapper__2XkKW.table_clearWithHeader__337kz tbody tr {
  border-bottom: none;
}

.table_wrapper__2XkKW tbody tr {
  cursor: pointer;
}

.table_wrapper__2XkKW tbody tr.table_active__2gp96 {
  background: var(--selected-row);
}

.table_wrapper__2XkKW thead tr td {
  color: var(--light-gray-table);
  white-space: nowrap;
  font-size: 12px;
}

.table_sortableWrapper__34Vxq {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.table_sortableWrapper__34Vxq.table_white__29e_w .table_sortableLabel__3WL0r {
  font-size: 9px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: var(--night);
  letter-spacing: 1.5px;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 16px;
  opacity: 1;
}

.table_sortableLabel__3WL0r,
.table_sortableIcon__11otQ {
  color: var(--cobalt);
  white-space: nowrap;
  font-size: 12px;
  opacity: 0.6;
}

.table_sortableWrapper__34Vxq.table_disabled__2LXU- {
  cursor: not-allowed;
}

.table_sortableIcon__11otQ {
  margin-left: 4px;
  color: var(--light-gray-font);
}

.table_sortableIcon__11otQ.table_active__2gp96,
.table_sortableLabel__3WL0r.table_active__2gp96 {
  color: var(--cobalt);
  opacity: 1;
  background: none;
}

.paginationPages_field__K0JEk {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paginationPages_rightSide__1de-j {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.paginationPages_pagesWrapper__qXnBr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}

.paginationPages_pagesWrapper__qXnBr * {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.paginationPages_white__1N50S .paginationPages_pageButton__2UirJ {
  background: #ffffff;
}

.paginationPages_whiteSelect__1kPeX {
  height: 32px !important;
  background: #ffffff;
}

.paginationPages_pageButton__2UirJ {
  cursor: pointer;
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 0 2px;
  border: 1px solid var(--new-light-gray);
}

.paginationPages_pageButton__2UirJ p {
  font-family: Inter, 'serif';
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: -0.5px !important;
  text-align: center !important;
}
.paginationPages_pageButton__2UirJ span {
  width: 18px !important;
}

.paginationPages_pageButton__2UirJ.paginationPages_active__vxPea {
  background: var(--new-light-gray);
  color: var(--game-blue) !important;
}

.empty_wrapper__2MYPk {
  background: var(--gray-file-upload);
  width: 100%;
  min-height: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  text-align: center;
  margin-top: 8px;
}

.empty_wrapper__2MYPk.empty_onlyTitle__3WAAg .empty_title__2rI6f {
  margin-bottom: 0;
}

.empty_wrapper__2MYPk.empty_white__4zNtD {
  background: var(--new-gray-4);
  border-radius: 6px;
  margin-top: 0;
}

.empty_wrapper__2MYPk.empty_white__4zNtD .empty_title__2rI6f,
.empty_wrapper__2MYPk.empty_white__4zNtD .empty_subtitle__1f0sI {
  max-width: 250px;
  width: 100%;
}

.empty_title__2rI6f {
  margin-bottom: 16px;
  max-width: 180px;
  width: 100%;
}

.empty_subtitle__1f0sI {
  max-width: 180px;
  width: 100%;
}

.categoriesModal_buttonsWrapper__3PYO- {
  display: flex;
  justify-content: flex-end;
}

.categoriesModal_buttonsWrapper__3PYO- button + button {
  margin-left: 8px;
}

.input_input__iN97O {
  background: var(--new-gray-6);
  border: 1px solid var(--new-gray-6);
  box-shadow: none;
  height: 140px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 16px;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.input_nonResizeAble__3lPI7 {
  resize: none;
}

.input_input__iN97O::-webkit-input-placeholder {
  color: var(--new-gray-3);
}

.input_input__iN97O:-ms-input-placeholder {
  color: var(--new-gray-3);
}

.input_input__iN97O::placeholder {
  color: var(--new-gray-3);
}

.input_input__iN97O:focus {
  border: 1px solid var(--new-blue);
}

.input_input__iN97O.input_hasError__36Mfy,
.input_input__iN97O.input_hasError__36Mfy:focus {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.input_input__iN97O[disabled] {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.input_input__iN97O[disabled]::-webkit-input-placeholder {
  color: var(--new-gray-5);
}

.input_input__iN97O[disabled]:-ms-input-placeholder {
  color: var(--new-gray-5);
}

.input_input__iN97O[disabled]::placeholder {
  color: var(--new-gray-5);
}

.inlineborderradius_field__1THzo,
.inlineborderradius_shortField__XJDJx,
.inlineborderradius_sliderField__1SgxS {
  display: flex;
  align-items: center;
}

.inlineborderradius_sliderField__1SgxS .inlineborderradius_elementWrapper__2UUxb:first-child {
  width: 70%;
}

.inlineborderradius_elementWrapper__2UUxb {
  margin-right: 6px;
}

.inlineborderradius_elementWrapper__2UUxb:last-child {
  margin-right: 0;
  flex: 1 1 100%;
}

.inlineborderradius_shortField__XJDJx .inlineborderradius_elementWrapper__2UUxb:last-child {
  margin-right: 0;
  flex: none;
}

.checkbox_wrapper__ReFER {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.checkbox_wrapper__ReFER.checkbox_disabled__1wTQ0 {
  cursor: not-allowed;
}

.checkbox_checkbox__2e3nO {
  display: none;
}

.checkbox_togglerWrapper__3OtqY {
  position: relative;
}

.checkbox_spotWrapper__xG1Af {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  transition: all 0.3s;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #d9d9d9;
}

.checkbox_togglerWrapper__3OtqY.checkbox_active__3NJXw .checkbox_spotWrapper__xG1Af {
  background-color: var(--game-blue);
  border-color: var(--game-blue);
}

.checkbox_togglerWrapper__3OtqY.checkbox_active__3NJXw .checkbox_spotWrapper__xG1Af:after {
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.checkbox_spotWrapper__xG1Af:after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition:
    all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
    opacity 0.1s;
  content: ' ';
}

.checkbox_togglerWrapper__3OtqY.checkbox_active__3NJXw:after {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: checkbox_antCheckboxEffect__17LuA 0.36s ease-in-out;
          animation: checkbox_antCheckboxEffect__17LuA 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}

.checkbox_errorsLabelWrapper__1dvB1 {
  margin-left: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.checkbox_label__1kLi4 {
  font-size: 14px;
  color: var(--night);
  font-family: 'Inter', sans-serif;
  font-weight: 400 !important;
  line-height: 22px !important;
  /* identical to box height, or 157% */
  letter-spacing: -0.2px !important;
}

.checkbox_errors__3_aAh {
  color: var(--new-red);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: right;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.24px;
  display: flex;
  flex-direction: column;
}

.checkbox_typo__7TYhD {
  height: 40px;
  display: flex;
  align-items: center;
}

.input_input__1RdGp {
  background: var(--new-gray-6);
  border: 1px solid var(--new-gray-6);
  box-shadow: none;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 16px;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.input_input__1RdGp::-webkit-input-placeholder {
  color: var(--new-gray-3);
}

.input_input__1RdGp:-ms-input-placeholder {
  color: var(--new-gray-3);
}

.input_input__1RdGp::placeholder {
  color: var(--new-gray-3);
}

.input_input__1RdGp:focus {
  border: 1px solid var(--new-blue);
}

.input_input__1RdGp.input_hasError__2t7fS,
.input_input__1RdGp.input_hasError__2t7fS:focus {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.input_input__1RdGp[disabled] {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.input_input__1RdGp.input_readOnly__1bTZ6 {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
}

.input_input__1RdGp[disabled]::-webkit-input-placeholder, .input_input__1RdGp.input_readOnly__1bTZ6::-webkit-input-placeholder {
  color: var(--new-gray-5);
}

.input_input__1RdGp[disabled]:-ms-input-placeholder, .input_input__1RdGp.input_readOnly__1bTZ6:-ms-input-placeholder {
  color: var(--new-gray-5);
}

.input_input__1RdGp[disabled]::placeholder,
.input_input__1RdGp.input_readOnly__1bTZ6::placeholder {
  color: var(--new-gray-5);
}

.input_select__2vqDN {
  margin-right: 8px;
  max-width: 130px;
  margin-bottom: 0;
}

.input_wrapper__2RRti {
  flex: 1 1 100%;
}

.input_inputs__1GeGP {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input_input__27cS7 {
  background: var(--new-gray-6);
  border: 1px solid var(--new-gray-6);
  box-shadow: none;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 16px 7px 36px;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.input_datepickerinput__EbMd4.input_tiny__2QfDY {
  height: 30px;
}

.input_datepickerinput__EbMd4 {
  margin-bottom: 0;
  margin-right: 0;
}

.input_input__27cS7[disabled]::-webkit-input-placeholder, .input_input__27cS7.input_readOnly__ophhF::-webkit-input-placeholder {
  color: var(--new-gray-5);
}

.input_input__27cS7[disabled]:-ms-input-placeholder, .input_input__27cS7.input_readOnly__ophhF:-ms-input-placeholder {
  color: var(--new-gray-5);
}

.input_input__27cS7[disabled]::placeholder,
.input_input__27cS7.input_readOnly__ophhF::placeholder {
  color: var(--new-gray-5);
}

.input_datepicker__2bcwX {
  font-family: 'Inter', sans-serif !important;
  border: 1px solid var(--new-light-gray-2) !important;
  border-radius: 5px !important;
  -webkit-filter: drop-shadow(0px 8px 16px rgba(67, 86, 100, 0.02)) drop-shadow(0px 16px 32px rgba(67, 86, 100, 0.16));
          filter: drop-shadow(0px 8px 16px rgba(67, 86, 100, 0.02)) drop-shadow(0px 16px 32px rgba(67, 86, 100, 0.16));
}

.input_datepicker__2bcwX .react-datepicker__triangle:after {
  border-bottom-color: var(--snow) !important;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.input_datepicker__2bcwX .react-datepicker__day--today {
  background-color: var(--game-blue-hover) !important;
  border-radius: 5px !important;
  color: var(--snow);
}

.input_datepicker__2bcwX .react-datepicker__time-container {
  border-left: 1px solid var(--new-light-gray-2);
}

.input_datepicker__2bcwX .react-datepicker__day--selected {
  background-color: var(--game-blue) !important;
  border-radius: 5px !important;
  color: var(--snow);
}

.input_datepicker__2bcwX li.react-datepicker__time-list-item--selected {
  background-color: var(--game-blue) !important;
  border-radius: 0 !important;
  color: var(--snow);
}

.input_datepicker__2bcwX .react-datepicker__triangle:before {
  border-bottom-color: var(--new-light-gray-2) !important;
}

.input_datepicker__2bcwX .react-datepicker__header {
  background: var(--snow);
  border-bottom: 1px solid var(--new-light-gray-2) !important;
}

.input_datepickerinput__EbMd4.input_error__2IlFz input {
  border: 1px solid var(--new-red) !important;
}

.whiteColorPicker_wrapper__1Gsvr {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--input-padding);
  min-width: 110px;
  min-height: 40px;
}

.whiteColorPicker_inputWrapper__I2wje {
  width: 110px;
  display: flex;
  position: relative;
}

.whiteColorPicker_valueInput__1991_ {
  box-shadow: none;
  border-radius: 5px;
  width: 100%;
  padding: 2px 18px 2px 26px;
  align-items: center;
  position: relative;
  background: var(--gray-input);
  color: var(--gray-600);
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  height: 40px;
  transition: border var(--animation);
  outline: none;
  border: 1px solid var(--gray-input);
}

.whiteColorPicker_valueInput__1991_.whiteColorPicker_tiny__kRS7H {
  height: 30px;
}

.whiteColorPicker_valueInput__1991_:focus {
  border: 1px solid var(--game-blue);
}

.whiteColorPicker_valueInput__1991_:active {
  border: 1px solid var(--game-blue);
}

.whiteColorPicker_inputWrapper__I2wje .whiteColorPicker_colorInput__1WDJZ {
  top: 0;
  left: 0;
  width: 30px;
  height: 100%;
  margin-right: 10px;
  opacity: 0;
  position: absolute;
}

.whiteColorPicker_valueInput__1991_.whiteColorPicker_danger__2XIvn {
  border: 1px solid #ff0000;
}

.whiteColorPicker_inputWrapper__I2wje .whiteColorPicker_colorInput__1WDJZ[disabled] {
  opacity: 0 !important;
}

.whiteColorPicker_inputWrapper__I2wje.whiteColorPicker_transparent__1azk_ .whiteColorPicker_valueInput__1991_ {
  background: var(--snow);
  color: var(--new-gray-5);
}

.whiteColorPicker_inputWrapper__I2wje.whiteColorPicker_disabled__2yyJ4 .whiteColorPicker_valueInput__1991_ {
  cursor: not-allowed;
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
}

.whiteColorPicker_color__1oaxE {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 8px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 1px solid var(--border-gray);
}

.whiteColorPicker_color__1oaxE.whiteColorPicker_transparent__1azk_:after {
  width: 1px;
  height: 100%;
  background: var(--red);
  top: 0;
  display: inline-block;
  left: 50%;
  content: '';
  -webkit-transform: rotateZ(45deg) translateX(-50%);
          transform: rotateZ(45deg) translateX(-50%);
  position: absolute;
}

.whiteColorPicker_clearBtn__3s_ok {
  border: none;
  background: none;
  position: absolute;
  right: 4px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  padding: 3px;
  outline: none;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whiteColorPicker_clearIcon__1b18T {
  width: 12px;
}

.input_wrapper__2jtiT {
  display: flex;
  flex-direction: column;
}

.input_label__rE4eL {
  color: var(--gray);
}

.input_errors__2OK6W {
  display: flex;
  flex-direction: column;
}

.input_error__3sGjj {
  color: #ff4d4f;
  font-size: 12px;
}

.input_white__1ujQV {
  padding: 5px;
}

.input_radioWrapper__3NPL8 {
  display: flex;
  flex-direction: row;
}

.input_radio__2Iptm {
  border: none;
  height: 40px;
  width: 40px;
  background: var(--gray-input);
  color: var(--gray-500);
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  cursor: pointer;
}

.input_radio__2Iptm.input_tiny__1GeRD {
  height: 30px;
  width: 30px;
}

.input_radio__2Iptm.input_textRadio__1ZAMr {
  width: auto;
  font-size: 12px;
}

.input_radio__2Iptm[disabled] {
  cursor: not-allowed;
  background: var(--gray-file-upload);
}

.input_radio__2Iptm.input_active__3ikX7[disabled] {
  background: var(--blue-line);
}

.input_radio__2Iptm:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.input_radio__2Iptm:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.input_radio__2Iptm.input_active__3ikX7 {
  background: var(--game-blue);
  color: var(--snow);
}

.input_slider__1SdiG {
  width: 100%;
}

.input_slider__1SdiG .ant-slider-track,
.input_slider__1SdiG:hover .ant-slider-track {
  background: var(--game-blue) !important;
}
.input_slider__1SdiG .ant-slider-handle,
.input_slider__1SdiG:hover .ant-slider-handle {
  border: 2px solid var(--game-blue) !important;
  background: var(--game-blue) !important;
}

.input_sliderWrapper__1aCav {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: var(--input-height);
}

.input_sliderValue__gQx9- {
  width: 35px;
  min-width: 35px;
  margin-left: 8px;
  font-size: var(--input-fsz);
  color: var(--black);
}

.input_sliderValue__gQx9-.input_disabled__3SVOw {
  color: var(--light-gray-font);
}

.input_slider__1SdiG.input_disabled__3SVOw .ant-slider-track,
.input_slider__1SdiG.input_disabled__3SVOw:hover .ant-slider-track {
  background: var(--blue-line) !important;
}
.input_slider__1SdiG.input_disabled__3SVOw .ant-slider-handle,
.input_slider__1SdiG.input_disabled__3SVOw:hover .ant-slider-handle {
  border: 2px solid var(--blue-line) !important;
}

.upload_modal__39XKj {
  width: 1100px !important;
}

.upload_mediaList__1llrg {
  display: flex;
  flex-wrap: wrap;
}

.upload_mediaList__1llrg .upload_item__3WdIy {
  padding: 8px;
}

.upload_imageWrapper__vutt_ {
  border-radius: 8px;
  overflow: hidden;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.upload_image__ujsMi {
  width: 100%;
  height: 100%;
}

.upload_imageWrapper__vutt_.upload_active__1FFjH {
  border: 1px solid var(--game-blue);
}

.upload_footerWrapper__1gu9O {
  width: 100%;
  justify-content: center;
  display: flex;
}

.upload_popoverhint__hxFsV {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
}

.upload_footer__3XanP {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--light-gray-border);
  width: 100%;
  padding-top: 20px;
}

.upload_editableBtn__185La {
  width: 100%;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background var(--animation);
  border: none;
  height: 25px;
}

.upload_editableBtn__185La.upload_small__331Mt {
  height: 20px;
}

.upload_editableBtn__185La.upload_small__331Mt .upload_text__1K5gr {
  font-size: 10px !important;
}

.upload_trashBtn__2UQDs {
  opacity: 0;
  position: absolute;
  top: 2px;
  right: 2px;
  transition: all 0.2s;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 2px;
  background: rgba(255, 255, 255, 0.75);
}

.upload_hover__2eV9H .upload_trashBtn__2UQDs {
  opacity: 1;
}

.upload_trashBtn__2UQDs:hover {
  background: rgba(255, 255, 255, 1);
}
.upload_trashIcon__1z_LB {
  width: 18px;
  height: 18px;
}

.upload_trashBtn__2UQDs.upload_small__331Mt .upload_trashIcon__1z_LB {
  width: 16px;
  height: 16px;
}

.upload_editableBtn__185La:hover {
  background: var(--snow);
}

.upload_cropperFooter__2vjeT {
  margin-top: 10px;
}

.upload_cropperFooter__2vjeT .upload_footer__3XanP button + button {
  margin-left: 5px;
}

.upload_footer__3XanP button {
  margin-top: 16px !important;
  width: 200px !important;
}

.upload_modalTitle__3jkTp {
  font-weight: 500;
  font-size: 32px;
  letter-spacing: -1.6px;
  margin-top: 32px;
  font-family: 'Inter', serif;
}

.upload_tabsWrapper__IaaI3 {
  display: flex;
  margin-bottom: 36px;
}

.upload_tab__LrgTo {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--border-gray);
}

.upload_tab__LrgTo .upload_label__286Yg {
  font-size: 16px;
  line-height: 110%;
  color: var(--gray-500);
}

.upload_tab__LrgTo .upload_tabIcon__3SxTN {
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.upload_tab__LrgTo .upload_tabIcon__3SxTN svg {
  fill: var(--gray-500);
}

.upload_tab__LrgTo.upload_active__1FFjH .upload_tabIcon__3SxTN svg {
  fill: var(--game-blue);
}

.upload_tab__LrgTo.upload_active__1FFjH p {
  color: #040415;
}

.upload_tab__LrgTo.upload_active__1FFjH {
  border-bottom: 2px solid var(--game-blue);
}

.upload_tab__LrgTo.upload_disabled__72cCE {
  cursor: not-allowed;
}

.upload_tab__LrgTo.upload_disabled__72cCE .upload_label__286Yg,
.upload_tab__LrgTo.upload_disabled__72cCE .upload_tabIcon__3SxTN {
  color: var(--gray-200);
}

.upload_actionsWrapper__14d3h {
  display: flex;
}
.upload_action__a5FTv {
  color: var(--game-blue) !important;
  background: var(--snow) !important;
  border: 1px solid var(--light-gray-border) !important;
  width: 120px !important;
  height: 32px !important;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.upload_action__a5FTv:first-child {
  border-radius: 16px 0 0 16px !important;
  border-right: 0 !important;
}

.upload_action__a5FTv:last-child {
  border-left: 0 !important;
  border-radius: 0 16px 16px 0 !important;
}

.upload_action__a5FTv.upload_active__1FFjH {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

.upload_removeBgBtn__2tnyg {
  margin: 32px auto 0;
}

.upload_uploader__2JgVv {
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 1px solid var(--new-gray-6);
  display: inline-block;
  overflow: hidden;
}

.upload_uploader__2JgVv.upload_big__1c2dk {
  width: 92px;
  height: 92px;
  min-width: 92px;
  min-height: 92px;
  max-width: 92px;
  max-height: 92px;
}

.upload_uploader__2JgVv.upload_disabled__72cCE,
.upload_uploader__2JgVv.upload_empty__JgULf.upload_disabled__72cCE,
.upload_uploader__2JgVv.upload_empty__JgULf.upload_disabled__72cCE:hover,
.upload_uploader__2JgVv.upload_empty__JgULf.upload_disabled__72cCE:focus,
.upload_uploader__2JgVv.upload_empty__JgULf.upload_disabled__72cCE:active {
  cursor: not-allowed;
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
  box-shadow: none;
}

.upload_uploader__2JgVv.upload_empty__JgULf {
  color: var(--game-blue);
  display: flex;
  background: var(--pastel-blue);
  border: 1px dashed var(--light-blue);
}

.upload_uploader__2JgVv.upload_empty__JgULf:hover {
  border: 1px solid var(--game-blue);
}

.upload_uploader__2JgVv.upload_empty__JgULf[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
}

.upload_uploader__2JgVv.upload_empty__JgULf:focus,
.upload_uploader__2JgVv.upload_empty__JgULf:active {
  background: var(--pastel-blue);
  border: 1px solid var(--game-blue);
}

.upload_uploaderButtonBody__2qnRX {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: 10px;
  text-align: center;
}

.upload_uploaderButtonIcon__3Kd0T {
  font-size: 16px;
  color: inherit;
  margin-bottom: var(--input-padding);
}

.upload_inputFile__akV9q {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.upload_inputFileBtn__mT6n7 {
  margin-top: 4px;
  position: relative !important;
  cursor: pointer !important;
}

.upload_uploader__2JgVv .upload_img__3dp9N {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  background-image: url(https://configurator.adact.brocoders.xyz/static/media/pngbackground.1ebf12dd.png);
  background-size: 100px 100px;
}

.upload_dndArea__8OSRc {
  width: 560px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 180px;
  border-radius: 10px;
  margin: auto auto 24px auto;
  background: var(--pastel-blue);
  border: 2px dashed var(--light-blue);
}

.upload_dndArea__8OSRc p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: var(--game-blue);
  letter-spacing: -0.3px !important;
}

.upload_modalWrapper__1gAkY {
}

.upload_dndArea__8OSRc.upload_canDrop__1qK48 {
  border: 1px solid var(--green);
  background: var(--green-opacity);
}

.upload_iconWrapper__1rfN9 {
  font-size: 32px;
  color: var(--game-blue);
}

.upload_sidesWrapper__2iA-o {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  justify-content: center;
}

.upload_sidesWrapper__2iA-o + .upload_footerWrapper__1gu9O {
  margin-top: 24px;
}

.upload_imageWrapper__vutt_ {
  display: flex;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
}

.upload_cropImageWrapper__2A5Av {
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  position: relative;
  overflow: hidden;
}

.upload_imagePreview__3f8M4 {
  max-height: 400px;
  object-fit: contain;
  max-width: 100%;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: auto;
}

.upload_cropWrapper__1Zc-w {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  align-items: center;
  width: 100%;
}

.upload_cropBox__TUEV8 {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px dashed var(--game-blue);
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.4);
}

.upload_cropPoint__3S8qJ {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  margin-top: -5px;
  margin-left: -5px;
}

.upload_backgroundEditorWrapper__3f2AZ {
  width: 100%;
  display: flex;
}

.upload_editorCanvas__28m7A {
  background-image: url(https://configurator.adact.brocoders.xyz/static/media/pngbackground.1ebf12dd.png);
  background-size: 100px 100px;
}

.upload_leftSide__2dE8z {
  display: flex;
  justify-content: center;
  max-width: 400px;
  height: 400px;
  width: 100%;
}

.upload_rightSide__3sfHy {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upload_imageSizesWrapper__6daWC {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.upload_chainIcon__3NU2U {
  font-size: 18px;
  color: var(--gray-font);
  position: absolute;
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.upload_removableWrapper__wtGBp.upload_hover__2eV9H .upload_buttonsImageWrapper__352x8 {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.upload_overlay__VJGdv {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.2s;
  background: rgba(0, 0, 0, 0);
}

.upload_removableWrapper__wtGBp.upload_hover__2eV9H .upload_overlay__VJGdv {
  background: rgba(0, 0, 0, 0.2);
}

.upload_buttonsImageWrapper__352x8 {
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
  height: 25px;
  align-items: flex-end;
  transition: all 0.2s;
}

.upload_buttonsImageWrapper__352x8.upload_small__331Mt {
  height: 20px;
  bottom: -20px;
}

.upload_btnsWrapper__39JUT {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload_footerBtn__2J4wa {
  margin-left: 5px;
}

.upload_btnsWrapper__39JUT button:not(:last-child) {
  margin-right: 8px;
}

.upload_arrow__2C_U_ {
  margin-right: 5px;
}

.upload_btnArrow__1LtbJ {
  height: 30px !important;
  width: 50% !important;
  border: none !important;
  font-size: 14px !important;
  box-shadow: none !important;
  background: none !important;
  color: var(--game-blue) !important;
}

.upload_btnArrow__1LtbJ span {
  border-bottom: 1px solid var(--game-blue) !important;
}

.upload_applyBtn__1ikgz {
  max-height: 40px !important;
  height: 40px !important;
  width: 50% !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

.upload_canvasWrapper__Lm0A3 {
  margin: auto;
}

.upload_modalConfirmText__faCal {
  margin-bottom: 8px;
}

.upload_addyThink__3j3fs {
  background: url(https://configurator.adact.brocoders.xyz/static/media/addythink.b59d5863.png) center top no-repeat;
  background-size: cover;
  width: 94px;
  height: 177px;
}

.upload_busyWrapper__NkKR8 {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload_busyWrapper__NkKR8 .upload_hint__AwZzr {
  margin-top: 24px;
  font-family: 'Roboto', 'serif';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
}

.mediaFiles_mediaList__27zx0 {
  display: flex;
  flex-wrap: wrap;
  height: 136px;
}

.mediaFiles_oneRow__1fLIh {
  height: 68px;
}

.mediaFiles_mediaList__27zx0 .mediaFiles_item__2QNvk {
  padding-right: 4px;
  padding-bottom: 4px;
}

.mediaFiles_pagesWrapper__18Zrk {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  margin: 10px auto;
}

.mediaFiles_pagesWrapper__18Zrk * {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.mediaFiles_pageButton__1f4IO {
  cursor: pointer;
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 0 2px;
  border: 1px solid var(--new-light-gray);
}

.mediaFiles_pageButton__1f4IO p {
  font-family: Inter, 'serif';
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: -0.5px !important;
  text-align: center !important;
}
.mediaFiles_pageButton__1f4IO span {
  width: 18px !important;
}

.mediaFiles_pageButton__1f4IO.mediaFiles_active__Mansa {
  background: var(--new-light-gray);
  color: var(--game-blue) !important;
}

.mediaFiles_inputFile__2n-bw {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.mediaFiles_inputFileBtn__GxXPg {
  width: 150px !important;
  height: 28px !important;
}
.mediaFiles_inputFileBtn__GxXPg * {
  cursor: pointer !important;
}

.mediaFiles_inputFileBtn__GxXPg input {
  color: transparent;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.mediaFiles_inputFileBtn__GxXPg .mediaFiles_imageWrapper__aqMao {
  background: #f0f2ff;
}

.mediaFiles_imageWrapper__aqMao {
  border: 2px solid var(--snow);
  overflow: hidden;
  width: 64px;
  height: 64px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
}

.mediaFiles_checkmark__10AMW {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
}

.mediaFiles_image__3Pu-d {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  height: 100%;
  background-image: url(https://configurator.adact.brocoders.xyz/static/media/pngbackground.1ebf12dd.png);
  background-size: 100px 100px;
}

.mediaFiles_uploaderButtonBody__xNuXG {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: 10px;
  text-align: center;
}

.mediaFiles_imageWrapper__aqMao.mediaFiles_active__Mansa {
  border: 2px solid var(--game-blue);
}

.mediaFiles_sidesWrapper__16CHL {
  display: flex;
}

.mediaFiles_leftSide__3veSc {
  width: 100%;
}

.mediaFiles_dndArea__15-3q {
  background: var(--pastel-blue);
  width: 540px;
  height: 172px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 10px;
  margin-top: 10px;
  border: 2px dashed var(--light-blue);
}

.mediaFiles_dndArea__15-3q p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: var(--game-blue);
  letter-spacing: -0.3px !important;
}

.mediaFiles_dndArea__15-3q.mediaFiles_canDrop__1APL2 {
  background: var(--green-opacity);
}

.mediaFiles_rightSide__3p4QF {
  width: 476px;
  min-width: 476px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mediaFiles_previewList__P1Ld0 {
  position: relative;
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.mediaFiles_previewImage__1oeFc {
  max-width: 100%;
  max-height: 100%;
}

.mediaFiles_previewImageWrapper__37skZ.mediaFiles_multipreview__32oBj .mediaFiles_previewImage__1oeFc {
  max-height: 80px;
}

.mediaFiles_previewImageWrapper__37skZ.mediaFiles_multipreview__32oBj {
  margin: 4px;
  min-width: 100px;
  max-width: 100px;
  max-height: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.mediaFiles_previewImageWrapper__37skZ .mediaFiles_previewImageContainer__1xRXz {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.mediaFiles_previewImageWrapper__37skZ.mediaFiles_multipreview__32oBj .mediaFiles_previewImageContainer__1xRXz {
  height: 80px;
  width: 80px;
  margin-bottom: 4px;
}

.mediaFiles_recentsTitle__OivAN {
  margin-top: 32px;
  padding-bottom: 12px;
  font-family: Inter, 'serif' !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 17.6px !important;
  text-align: left !important;
}

.mediaFiles_sectionTitle__2y-mK {
  padding-bottom: 8px;
  font-family: Inter, serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 17.6px !important;
  text-align: left !important;
}

.mediaFiles_filtersWrapper__2-0vn {
  display: flex;
  margin-top: 15px;
}

.mediaFiles_topCategory__ZSFO8 {
  width: 258px;
}

.mediaFiles_subCategory__25s8b {
  width: 258px;
  margin-left: 24px;
}

.mediaFiles_topCategory__ZSFO8 label,
.mediaFiles_subCategory__25s8b label {
  margin-right: 12px !important;
  font-family: Inter, 'serif' !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16.94px !important;
  letter-spacing: -0.02em !important;
  text-align: left !important;
}

.mediaFiles_spinnerWrapper__1bYSg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mediaFiles_spinnerWrapper__1bYSg.mediaFiles_deleteSpinner__1VO19 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}

.mediaFiles_spinnerWrapper__1bYSg.mediaFiles_deleteSpinner__1VO19 .mediaFiles_spinner__2h_WC div {
  display: none;
}

.mediaFiles_inputFileBtn__GxXPg {
  position: relative !important;
}

.mediaFiles_iconWrapper__2kNbM {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.mediaFiles_iconWrapper__2kNbM img {
  width: 100%;
  height: 100%;
}

.mediaFiles_uploadHint__2t0sF {
  font-size: 14px !important;
  font-family: Inter, 'serif' !important;
  font-weight: 400 !important;
  letter-spacing: -0.30000001192092896px !important;
  text-align: center !important;
}
.mediaFiles_uploadHint__2t0sF + .mediaFiles_uploadHint__2t0sF {
  margin-top: -10px;
  margin-bottom: 5px;
}

.mediaFiles_iconWrapper__2kNbM svg {
  width: 80%;
  height: 80%;
}

.mediaFiles_footerWrapper__MJ8kq {
  height: 40px;
  min-height: 40px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mediaFiles_footerIcon__3y7qF {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: var(--new-gray-4);
  cursor: not-allowed;
}

.mediaFiles_footerIcon__3y7qF * {
  width: 20px;
}

.mediaFiles_footerIcon__3y7qF.mediaFiles_active__Mansa {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid var(--new-gray-5);
}

.mediaFiles_footerBtn__21NFH {
  cursor: pointer;
  height: 40px !important;
  min-height: 40px;
  width: 140px !important;
}

.mediaFiles_footerBtn__21NFH.mediaFiles_inActive__15VJx {
  background: var(--new-gray-4) !important;
  border: 1px solid #d0d5dd !important;
  cursor: not-allowed;
}

.mediaFiles_footerIcon__3y7qF + .mediaFiles_footerIcon__3y7qF,
.mediaFiles_footerIcon__3y7qF + .mediaFiles_footerBtn__21NFH {
  margin-left: 8px;
}

.mediaFiles_emptyPreview__iO50x {
  width: 100%;
  height: 100%;
  background: #f2f4f7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.mediaFiles_emptyPreview__iO50x p {
  font-family: Inter, 'serif';
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #475467;
}

.categoriesModal_buttonsWrapper__189pV {
  justify-content: flex-end;
  display: flex;
}

.categoriesModal_buttonsWrapper__189pV button + button {
  margin-left: 8px;
}

.mediaFiles_item__DcDO5 {
  padding-right: 4px;
  padding-bottom: 4px;
}

.mediaFiles_inputFileBtn__SFnxz .mediaFiles_imageWrapper__2rCa7 {
  background: #f0f2ff;
}

.mediaFiles_imageWrapper__2rCa7 {
  border: 2px solid var(--snow);
  overflow: hidden;
  width: 64px;
  height: 64px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
}

.mediaFiles_imageWrapper__2rCa7.mediaFiles_active__39pWw {
  border: 2px solid var(--game-blue);
}

.mediaFiles_image__3_3Uq {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  height: 100%;
  background-image: url(https://configurator.adact.brocoders.xyz/static/media/pngbackground.1ebf12dd.png);
  background-size: 100px 100px;
}

.mediaFiles_checkmark__e2QXc {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
}

.mediaFiles_spinnerWrapper__2qCga {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mediaFiles_spinnerWrapper__2qCga.mediaFiles_deleteSpinner__1_3wk {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}

.mediaFiles_spinnerWrapper__2qCga.mediaFiles_deleteSpinner__1_3wk .mediaFiles_spinner__258TT div {
  display: none;
}

.editModal_modal__1Guiw {
  width: 1000px !important;
}

.editModal_mediaList__259H_ {
  display: flex;
  flex-wrap: wrap;
}

.editModal_mediaList__259H_ .editModal_item__2BZhe {
  padding: 8px;
}

.editModal_imageWrapper__2Qc4b {
  border-radius: 8px;
  overflow: hidden;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.editModal_image__1yqU6 {
  width: 100%;
  height: 100%;
}

.editModal_imageWrapper__2Qc4b.editModal_active__3fgZv {
  border: 1px solid var(--game-blue);
}

.editModal_footerWrapper__8w0HE {
  width: 100%;
  justify-content: center;
  display: flex;
}

.editModal_popoverhint__osQr- {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
}

.editModal_footer__2rhJF {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--light-gray-border);
  width: 100%;
  padding-top: 20px;
}

.editModal_editableBtn__1fV1W {
  background: rgba(255, 255, 255, 0.85);
  border-radius: 12px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background var(--animation);
  border: none;
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
}

.editModal_editableBtn__1fV1W.editModal_small__1RUZ8 {
  width: 22px;
  height: 22px;
  max-width: 22px;
  max-height: 22px;
  border-radius: 6px;
  margin-right: 2px;
}
.editModal_editableBtn__1fV1W.editModal_small__1RUZ8:last-child {
  margin-left: 2px;
  margin-right: 0;
}
.editModal_editableBtn__1fV1W.editModal_small__1RUZ8 .editModal_editableIcon__21qer {
  width: 16px;
  height: 16px;
}

.editModal_editableBtn__1fV1W:hover {
  background: var(--snow);
}

.editModal_cropperFooter__2ic4Z {
  margin-top: 10px;
}

.editModal_cropperFooter__2ic4Z .editModal_footer__2rhJF button + button {
  margin-left: 5px;
}

.editModal_footer__2rhJF button {
  margin-top: 16px !important;
  width: 200px !important;
}

.editModal_modalTitle__1vlzC {
  font-weight: 500;
  font-size: 32px;
  letter-spacing: -1.6px;
  margin-top: 32px;
  font-family: 'Inter', serif;
}

.editModal_tabsWrapper__1T1w1 {
  display: flex;
  margin-bottom: 36px;
}

.editModal_tab__szVfG {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--border-gray);
}

.editModal_tab__szVfG .editModal_label__37nLF {
  font-size: 16px;
  line-height: 110%;
  color: var(--gray-500);
}

.editModal_tab__szVfG .editModal_tabIcon__3361l {
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.editModal_tab__szVfG .editModal_tabIcon__3361l svg {
  fill: var(--gray-500);
}

.editModal_tab__szVfG.editModal_active__3fgZv .editModal_tabIcon__3361l svg {
  fill: var(--game-blue);
}

.editModal_tab__szVfG.editModal_active__3fgZv p {
  color: #040415;
}

.editModal_tab__szVfG.editModal_active__3fgZv {
  border-bottom: 2px solid var(--game-blue);
}

.editModal_tab__szVfG.editModal_disabled__1TtvU {
  cursor: not-allowed;
}

.editModal_tab__szVfG.editModal_disabled__1TtvU .editModal_label__37nLF,
.editModal_tab__szVfG.editModal_disabled__1TtvU .editModal_tabIcon__3361l {
  color: var(--gray-200);
}

.editModal_actionsWrapper__29lVf {
  display: flex;
}
.editModal_action__20yKf {
  color: var(--game-blue) !important;
  background: var(--snow) !important;
  border: 1px solid var(--light-gray-border) !important;
  width: 120px !important;
  height: 32px !important;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.editModal_action__20yKf:first-child {
  border-radius: 16px 0 0 16px !important;
  border-right: 0 !important;
}

.editModal_action__20yKf:last-child {
  border-left: 0 !important;
  border-radius: 0 16px 16px 0 !important;
}

.editModal_action__20yKf.editModal_active__3fgZv {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

.editModal_removeBgBtn__1npsj {
  margin: 32px auto 0;
}

.editModal_uploader__TVkEM {
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  max-width: 56px;
  max-height: 56px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 1px solid var(--new-gray-6);
  display: inline-block;
}

.editModal_uploader__TVkEM.editModal_big__ZH_QM {
  width: 92px;
  height: 92px;
  min-width: 92px;
  min-height: 92px;
  max-width: 92px;
  max-height: 92px;
}

.editModal_uploader__TVkEM.editModal_disabled__1TtvU,
.editModal_uploader__TVkEM.editModal_empty__3TeQn.editModal_disabled__1TtvU,
.editModal_uploader__TVkEM.editModal_empty__3TeQn.editModal_disabled__1TtvU:hover,
.editModal_uploader__TVkEM.editModal_empty__3TeQn.editModal_disabled__1TtvU:focus,
.editModal_uploader__TVkEM.editModal_empty__3TeQn.editModal_disabled__1TtvU:active {
  cursor: not-allowed;
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
  box-shadow: none;
}

.editModal_uploader__TVkEM.editModal_empty__3TeQn {
  color: var(--game-blue);
  display: flex;
  background: var(--pastel-blue);
  border: 1px dashed var(--light-blue);
}

.editModal_uploader__TVkEM.editModal_empty__3TeQn:hover {
  border: 1px solid var(--game-blue);
}

.editModal_uploader__TVkEM.editModal_empty__3TeQn[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
}

.editModal_uploader__TVkEM.editModal_empty__3TeQn:focus,
.editModal_uploader__TVkEM.editModal_empty__3TeQn:active {
  background: var(--pastel-blue);
  border: 1px solid var(--game-blue);
}

.editModal_uploaderButtonBody__3zWj- {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: 10px;
  text-align: center;
}

.editModal_uploaderButtonIcon__3rDa3 {
  font-size: 16px;
  color: inherit;
  margin-bottom: var(--input-padding);
}

.editModal_inputFile__3JPki {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.editModal_inputFileBtn__ctr20 {
  margin-top: 4px;
  position: relative !important;
  cursor: pointer !important;
}

.editModal_uploader__TVkEM .editModal_img__w_mkX {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  background-image: url(https://configurator.adact.brocoders.xyz/static/media/pngbackground.1ebf12dd.png);
  background-size: 100px 100px;
}

.editModal_dndArea__3GQ6P {
  width: 560px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 180px;
  border-radius: 10px;
  margin: auto auto 24px auto;
  background: var(--pastel-blue);
  border: 2px dashed var(--light-blue);
}

.editModal_dndArea__3GQ6P p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: var(--game-blue);
  letter-spacing: -0.3px !important;
}

.editModal_modalWrapper__30uDW {
}

.editModal_dndArea__3GQ6P.editModal_canDrop__3B3iD {
  border: 1px solid var(--green);
  background: var(--green-opacity);
}

.editModal_iconWrapper__3wp6P {
  font-size: 32px;
  color: var(--game-blue);
}

.editModal_sidesWrapper__3lY_5 {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  justify-content: center;
}

.editModal_sidesWrapper__3lY_5 + .editModal_footerWrapper__8w0HE {
  margin-top: 24px;
}

.editModal_imageWrapper__2Qc4b {
  display: flex;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
}

.editModal_cropImageWrapper__Nsxxt {
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  position: relative;
  overflow: hidden;
}

.editModal_imagePreview__2V9Cf {
  max-height: 400px;
  object-fit: contain;
  max-width: 100%;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: auto;
}

.editModal_cropWrapper__2dNZV {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  align-items: center;
  width: 100%;
}

.editModal_cropBox__38_Ke {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px dashed var(--game-blue);
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.4);
}

.editModal_cropPoint__1Ablh {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  margin-top: -5px;
  margin-left: -5px;
}

.editModal_backgroundEditorWrapper__3FSB_ {
  width: 100%;
  display: flex;
}

.editModal_editorCanvas__hi7LQ {
  background-image: url(https://configurator.adact.brocoders.xyz/static/media/pngbackground.1ebf12dd.png);
  background-size: 100px 100px;
}

.editModal_leftSide__2ZO_r {
  display: flex;
  justify-content: center;
  max-width: 400px;
  height: 400px;
  width: 100%;
}

.editModal_rightSide__28FYA {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editModal_imageSizesWrapper__158Wb {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.editModal_chainIcon__1hiTs {
  font-size: 18px;
  color: var(--gray-font);
  position: absolute;
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.editModal_removableWrapper__34eIs {
  position: relative;
}

.editModal_removableWrapper__34eIs:hover .editModal_buttonsImageWrapper__3Wfsj {
  display: flex;
}

.editModal_buttonsImageWrapper__3Wfsj {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 4px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.editModal_removableWrapper__34eIs.editModal_both__1FFmr .editModal_buttonsImageWrapper__3Wfsj {
  justify-content: space-between;
}

.editModal_btnsWrapper__11yjH {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editModal_footerBtn__1mEJh {
  margin-left: 5px;
}

.editModal_btnsWrapper__11yjH button:not(:last-child) {
  margin-right: 8px;
}

.editModal_arrow__qSedt {
  margin-right: 5px;
}

.editModal_btnArrow__1B67j {
  height: 30px !important;
  width: 50% !important;
  border: none !important;
  font-size: 14px !important;
  box-shadow: none !important;
  background: none !important;
  color: var(--game-blue) !important;
}

.editModal_btnArrow__1B67j span {
  border-bottom: 1px solid var(--game-blue) !important;
}

.editModal_applyBtn__1UfDV {
  max-height: 40px !important;
  height: 40px !important;
  width: 50% !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

.editModal_canvasWrapper__LQO5B {
  margin: auto;
}

.editModal_modalConfirmText__25J63 {
  margin-bottom: 8px;
}

.editModal_addyThink__3R__d {
  background: url(https://configurator.adact.brocoders.xyz/static/media/addythink.b59d5863.png) center top no-repeat;
  background-size: cover;
  width: 94px;
  height: 177px;
}

.editModal_busyWrapper__2pI2B {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editModal_busyWrapper__2pI2B .editModal_hint__3pQwi {
  margin-top: 24px;
  font-family: 'Roboto', 'serif';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
}

.editModal_rowWrapper__gIg21 {
  margin: 0 auto 12px;
  border-radius: 6px;
  width: 100%;
  max-width: 560px;
  height: 72px;
  padding: 16px;
  border: 1px solid var(--gray-200);
  background: var(--snow);
  display: flex;
  justify-content: space-between;
}

.editModal_titleWrapper__3EFuL {
  display: flex;
  max-height: 100%;
  width: 100%;
  margin-right: 8px;
  align-items: center;
}

.editModal_rowImage__1UlPH {
  max-width: 40px;
  max-height: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}

.editModal_imageInfo__3Sepb {
  margin-left: 8px;
  width: 100%;
}

.editModal_title___ZoXm {
  color: #000000;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
}

.editModal_failed__3WHvI {
  color: var(--red-500);
  /* Text sm/Regular */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.editModal_size__q1hp0 {
  color: #686872 !important;
  color: var(--gray-500, #686872) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.editModal_progressWrapper__2Ezy9 {
  width: 90%;
  border-radius: 4px;
  background: var(--gray-200);
  position: relative;
  height: 6px;
  display: flex;
  align-items: center;
}

.editModal_barStyles__3BX-D {
  border-radius: 4px;
  height: 6px;
  background: var(--game-blue);
  transition: width 400ms ease-in-out;
  position: absolute;
}

.editModal_barLabel__3Jsdf {
  position: absolute;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  right: -40px;
}

.editModal_editButton__xcUAe {
  width: 70px !important;
  min-width: 70px !important;
}

.editModal_deleteButton__1EgC_ {
  width: 90px !important;
  min-width: 90px !important;
}

.editModal_icon__3M9a4 {
  width: 24px;
  height: 24px;
}

.editModal_wideButton__2qKs7 {
  width: 117px !important;
  min-width: 117px !important;
}

.editModal_buttonsWrapper__3tj-D {
  display: flex;
}

.editModal_buttonsWrapper__3tj-D button + button {
  margin-left: 8px;
}

.radio_wrapper__3qMg6 {
  display: flex;
  flex-direction: column;
  width: auto;
}

.radio_label__lOQzU {
  color: var(--night);
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: -0.02em !important;
}

.radio_radio__27E7n {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.radio_radio__27E7n.radio_disabledRadio__3kYOA {
  cursor: not-allowed;
}

.radio_radio__27E7n.radio_disabledRadio__3kYOA .radio_label__lOQzU {
  color: var(--new-dark-gray);
}

.radio_radioWrapper__2TMl0 {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}

.radio_radioWrapper__2TMl0 input {
  display: none;
}

.radio_spot__2UAiF:after {
  content: '';
  width: 14px;
  height: 14px;
  display: inline-block;
  transition:
    width ease 0.2s,
    height ease 0.2s;
  border-radius: 50%;
  background: var(--snow);
}

.radio_spot__2UAiF {
  width: 18px;
  position: relative;
  transition: background ease 0.2s;
  background: var(--gray-300);
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio_spot__2UAiF.radio_checked__32UKz {
  background: var(--game-blue);
}

.radio_spot__2UAiF.radio_checked__32UKz:after {
  width: 6px;
  height: 6px;
}

.radio_spot__2UAiF.radio_disabled__1AmwQ,
.radio_spot__2UAiF.radio_checked__32UKz.radio_disabled__1AmwQ {
  background: var(--gray-200);
}

.radio_spot__2UAiF.radio_checked__32UKz.radio_disabled__1AmwQ:after {
  background: var(--gray-300);
}

.radio_spot__2UAiF.radio_checked__32UKz.radio_disabled__1AmwQ:before {
  display: inline-block;
}

.radio_spot__2UAiF:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: none;
  border: 2px solid var(--gray-300);
  border-radius: 50%;
}

.radio_spot__2UAiF.radio_checked__32UKz:after {
  width: 6px;
  height: 6px;
}

.input_select__fi2YQ {
  position: relative;
}

.input_wrapper__3O0F9 {
  width: 150px;
}

.input_wrapper__3O0F9.input_inlineWrapper__1YpFb {
  width: 100%;
}
.input_wrapper__3O0F9.input_inline__3d2L1 .input_toggler__2D5Jp {
  flex: 1 1 100%;
}

.input_errors__1mboU {
  color: var(--new-red);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: right;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.24px;
  display: flex;
  flex-direction: column;
}

.input_listWrapper__17Qez {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  width: 100%;
  z-index: 2;
  -webkit-filter: var(--filter-shadow);
          filter: var(--filter-shadow);
  background: var(--snow);
  border: 1px solid var(--new-light-gray-2);
  border-radius: 5px;
}

.input_select__fi2YQ.input_active__1-zOq .input_listWrapper__17Qez {
  display: flex;
}

.input_select__fi2YQ.input_active__1-zOq .input_arrow__ITsOL {
  -webkit-transform: rotateZ(-180deg);
          transform: rotateZ(-180deg);
}

.input_listWrapper__17Qez ul {
  padding: 15px 6px;
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 0;
}

.input_listWrapper__17Qez ul li {
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: var(--night);
  margin-bottom: 6px;
  font-family: 'Inter', sans-serif;
}

.input_listWrapper__17Qez ul li label {
  cursor: pointer;
}

.input_listWrapper__17Qez ul li:hover {
  background: var(--pastel-blue);
}

.input_toggler__2D5Jp {
  border: 1px solid var(--new-gray-6);
  background: var(--new-gray-6);
  box-shadow: none;
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 32px 7px 16px;
  align-items: center;
  display: flex;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.input_toggler__2D5Jp.input_tiny__mAL1N {
  height: 30px;
}

.input_arrow__ITsOL {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 10px;
  height: 7px;
  margin-top: -3.5px;
  pointer-events: none;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.input_placeholder__1PdtR {
  color: var(--new-gray-3);
}

.input_select__fi2YQ.input_active__1-zOq .input_toggler__2D5Jp {
  border: 1px solid var(--new-blue);
}

.input_toggler__2D5Jp.input_hasError__2yaW0,
.input_select__fi2YQ.input_active__1-zOq .input_toggler__2D5Jp.input_hasError__2yaW0 {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.input_toggler__2D5Jp.input_disabled__y8iXh {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.input_toggler__2D5Jp.input_disabled__y8iXh .input_placeholder__1PdtR {
  color: var(--new-gray-5);
}

.rte_rte__fqQhD .tox-tinymce {
  border: none !important;
  border-radius: 0 !important;
}

.rte_title__pRLvQ {
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-bottom: 12px;
}

.rte_rte__fqQhD {
  width: 100%;
  position: relative;
}

.rte_shortCodesButtonWrapper__30GPR {
  top: 100px;
  z-index: 2;
  position: absolute;
}

.rte_buttonsWrapper__37-XO {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}

.rte_shortCodeButton__2AOOF,
.rte_shortCodesButtonWrapper__30GPR button {
  border-radius: 5px !important;
  border: 1px solid var(--new-gray-6) !important;
  background: var(--new-gray-6) !important;
  color: #000000 !important;
  font-weight: 600 !important;
  margin-bottom: 8px;
}

.rte_shortCodesButtonWrapper__30GPR .rte_button__3BJtP {
  color: var(--game-blue) !important;
  border: 1px solid var(--game-blue) !important;
}

.rte_shortCodeButton__2AOOF {
  margin-right: 5px;
}

.rte_shortCodeButton__2AOOF.rte_active__2unlh {
  background: var(--game-blue) !important;
  color: #ffffff !important;
}

.rte_codeHint__qnROW {
  font-size: 14px !important;
  max-width: 400px;
  word-wrap: break-word;
  margin-top: 8px;
}

.rte_rte__fqQhD .tox .tox-toolbar__group {
  height: var(--rte-btn-height) !important;
  margin-right: 6px !important;
  margin-bottom: 6px !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 0 !important;
  box-sizing: content-box;
}

.rte_rte__fqQhD.rte_shortCodes__2glSt .tox .tox-editor-header {
  margin-bottom: 20px !important;
}

.rte_rte__fqQhD .tox .tox-split-button {
  height: var(--rte-btn-height) !important;
  margin: 0 !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 0 !important;
  box-sizing: content-box;
}

.rte_rte__fqQhD .tox .tox-toolbar__group:last-child {
  margin-right: 0 !important;
}

.rte_rte__fqQhD .tox .tox-tbtn {
  background: var(--new-gray-6);
  height: 26px !important;
  margin: 0 !important;
  border-radius: 0 !important;
  border-top: 1px solid var(--new-gray-6) !important;
  border-bottom: 1px solid var(--new-gray-6) !important;
  border-right: 1px solid var(--new-gray-6) !important;
  padding: 0 !important;
}

.rte_rte__fqQhD .tox .tox-tbtn:first-child {
  border-radius: 5px 0 0 5px !important;
  border: 1px solid var(--new-gray-6) !important;
}
.rte_rte__fqQhD .tox .tox-tbtn:last-child {
  border-radius: 0 5px 5px 0 !important;
  border: 1px solid var(--new-gray-6) !important;
  border-left: none !important;
}

.rte_rte__fqQhD .tox .tox-tbtn:only-child {
  border-radius: 5px !important;
  border: 1px solid var(--new-gray-6) !important;
}

.rte_rte__fqQhD .tox .tox-split-button__chevron {
  border-radius: 0 5px 5px 0 !important;
}

.rte_rte__fqQhD .tox .tox-tbtn:hover.rte .tox .tox-tbtn:focus {
  background: none !important;
  color: var(--game-blue) !important;
}

.rte_rte__fqQhD .tox .tox-tbtn--enabled.rte .tox .tox-tbtn--enabled:hover {
  background: var(--game-blue) !important;
}

.rte_rte__fqQhD .tox .tox-tbtn:hover {
  background: var(--game-blue) !important;
}
.rte_rte__fqQhD .tox .tox-tbtn:hover svg {
  fill: var(--snow) !important;
}
.rte_rte__fqQhD .tox .tox-tbtn--enabled svg.rte .tox .tox-tbtn--enabled:hover svg {
  fill: var(--snow) !important;
}
.rte_rte__fqQhD .tox .tox-tbtn--bespoke:hover .tox-tbtn__select-label {
  color: var(--snow) !important;
}

.rte_rte__fqQhD .tox .tox-toolbar.rte .tox .tox-toolbar__overflow.rte .tox .tox-toolbar__primary {
  background-image: none !important;
}

.rte_rte__fqQhD .tox .tox-edit-area__iframe {
  background: var(--new-gray-6);
}

.rte_rte__fqQhD .tox .tox-sidebar-wrap {
  border: 1px solid var(--new-gray-6) !important;
  border-radius: 8px 8px 0 0 !important;
  margin-top: 24px;
}

.rte_rte__fqQhD .tox .tox-split-button .tox-tbtn.rte .tox .tox-split-button {
  height: var(--rte-btn-height) !important;
}

.rte_rte__fqQhD .tox .tox-edit-area {
  border-radius: 8px 8px 0 0 !important;
}

.rte_rte__fqQhD .tox .tox-statusbar {
  border: 1px solid var(--new-gray-6) !important;
  border-top: none !important;
  background: var(--new-gray-6);
  border-radius: 0 0 8px 8px !important;
}

.rte_rte__fqQhD .tox .tox-dialog {
  border-radius: 8px !important;
  border: none !important;
}

.rte_rte__fqQhD .tox .tox-dialog .tox-dialog__footer {
  border: none;
}

.rte_rte__fqQhD .tox .tox-dialog .tox-dialog__footer-end .tox-button {
  border-radius: 15px !important;
  background: var(--game-blue);
}

.rte_rte__fqQhD .tox .tox-dialog .tox-dialog__footer-end .tox-button.tox-button--secondary {
  border-radius: 15px !important;
  background: var(--snow);
  color: var(--game-blue);
  border-color: var(--game-blue);
}

.inlineborderradius_field__2VdoS,
.inlineborderradius_shortField__BeOQO,
.inlineborderradius_sliderField__1btSq {
  display: flex;
  align-items: center;
}

.inlineborderradius_sliderField__1btSq .inlineborderradius_elementWrapper__3ZIMy:first-child {
  width: 70%;
}

.inlineborderradius_elementWrapper__3ZIMy {
  margin-right: 6px;
}

.inlineborderradius_elementWrapper__3ZIMy:last-child {
  margin-right: 0;
  flex: 1 1 100%;
}

.inlineborderradius_shortField__BeOQO .inlineborderradius_elementWrapper__3ZIMy:last-child {
  margin-right: 0;
  flex: none;
}

.inlineborderradius_field__mPESb,
.inlineborderradius_shortField__31JS_,
.inlineborderradius_sliderField__3qqnx {
  display: flex;
  align-items: center;
  min-width: 142px;
}

.inlineborderradius_sliderField__3qqnx .inlineborderradius_elementWrapper__2MMTJ:first-child {
  width: 70%;
}

.inlineborderradius_elementWrapper__2MMTJ {
  margin-right: 6px;
}

.inlineborderradius_elementWrapper__2MMTJ:last-child {
  margin-right: 0;
  flex: 1 1 100%;
}

.inlineborderradius_shortField__31JS_ .inlineborderradius_elementWrapper__2MMTJ:last-child {
  margin-right: 0;
  flex: none;
}

.inlineborderradius_input__Esib9 {
  margin-bottom: 8px;
  max-width: 76px;
}

.inlineborderradius_select__24RoU {
  padding: 7px 24px 7px 16px;
  min-width: 76px;
}

.confirm_wrapper__3Er3F {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  right: 0;
  bottom: 0;
  z-index: 1010;
  background: rgba(16, 12, 49, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: confirm_opacity-on__142UW 0.2s;
          animation: confirm_opacity-on__142UW 0.2s;
}

.confirm_modal__MzSzy {
  width: 560px;
  min-height: 300px;
  position: relative;
  display: flex;
  flex-direction: row;
  background: var(--snow);
  border-radius: 12px;
  -webkit-animation: confirm_scale-up-center__1oD-L 0.2s;
          animation: confirm_scale-up-center__1oD-L 0.2s;
  box-shadow:
    0 16px 22px rgba(0, 0, 0, 0.1),
    0 34px 55px rgba(0, 0, 0, 0.05),
    0 13px 66px rgba(0, 0, 0, 0.07);
}

.confirm_wrapper__3Er3F.confirm_closing__1OZ6e .confirm_modal__MzSzy {
  -webkit-animation: confirm_scale-down-center__1XHaT 0.2s;
          animation: confirm_scale-down-center__1XHaT 0.2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.confirm_wrapper__3Er3F.confirm_closing__1OZ6e {
  -webkit-animation: confirm_opacity-off__3l8oF 0.2s;
          animation: confirm_opacity-off__3l8oF 0.2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.confirm_cancelBtn__30lK0 {
  margin-top: 16px;
}

.confirm_btnsWrapper__1pCxt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm_body__2qrJe {
  width: 50%;
  padding: 48px 0 48px 32px;
  display: flex;
  flex-direction: column;
}

.confirm_close__20mai {
  position: absolute;
  top: 12px;
  right: 12px;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: var(--gray-350);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out;
  padding: 0;
}

.confirm_close__20mai:hover {
  background: var(--gray-300);
}

.confirm_addy__fIhoj {
  position: absolute;
  bottom: -15px;
  pointer-events: none;
  right: 0;
}

.confirm_ground__IhZkT {
  position: absolute;
  pointer-events: none;
  bottom: 0;
  right: 0;
  border-radius: 0 0 12px 0;
}

.confirm_title__2lFNo {
  margin-bottom: 16px;
}

.confirm_description__n5cY1 {
  margin-bottom: 32px;
  line-height: 28px;
}

@-webkit-keyframes confirm_scale-up-center__1oD-L {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes confirm_scale-up-center__1oD-L {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes confirm_scale-down-center__1XHaT {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes confirm_scale-down-center__1XHaT {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@-webkit-keyframes confirm_opacity-on__142UW {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes confirm_opacity-on__142UW {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes confirm_opacity-off__3l8oF {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes confirm_opacity-off__3l8oF {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes confirm_scale-down-center__1XHaT {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

.defaultBrandSettings_popoverTitle__1ohyg {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 14px;
}

.defaultBrandSettings_modal__2-77G > div {
  width: 600px;
}

.defaultBrandSettings_modal__2-77G h1 {
  font-size: 26px !important;
}

.defaultBrandSettings_modal__2-77G > div > div {
  width: 70%;
}

.defaultBrandSettings_modal__2-77G > div > div > div {
  flex-direction: row-reverse;
  justify-content: center;
  width: 80%;
}

.defaultBrandSettings_modal__2-77G button {
  margin: unset;
}

.link_link__1A_GF {
  color: var(--dark-yellow);
  cursor: pointer;
}

.link_blue__4o61L {
  color: var(--game-blue);
  text-decoration: underline;
}

.white_wrapper__3hlTF {
  display: flex;
  position: relative;
  min-width: 160px;
}

.white_listWrapper__3pKJA {
  position: absolute;
  top: 100%;
  min-width: 100%;
  right: 0;
  z-index: 2;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 5px;
  background: var(--snow);
  border: 1px solid #e2e2ea;
  border-radius: 5px;
  -webkit-filter: drop-shadow(0px 8px 16px rgba(67, 86, 100, 0.02)) drop-shadow(0px 16px 32px rgba(67, 86, 100, 0.16));
          filter: drop-shadow(0px 8px 16px rgba(67, 86, 100, 0.02)) drop-shadow(0px 16px 32px rgba(67, 86, 100, 0.16));
}

.white_list__3kjot {
  padding: 10px 6px;
  margin: 0;
  list-style: none;
}
.white_list__3kjot li {
  margin-bottom: 2px;
}

.white_item__1pa_M {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 6px;
  padding: 6px 10px;
}

.white_itemBody__3bzul {
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.white_active__AwazY {
  border-radius: 5px;
  padding: 10px 16px;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
}

.white_active__AwazY.white_select__1RYd8 {
  border: 1px solid var(--new-light-gray);
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  padding: 10px 30px 10px 16px;
}

.white_active__AwazY.white_select__1RYd8.white_open__3VDIs {
  border: 1px solid var(--game-blue);
}

.white_active__AwazY.white_select__1RYd8.white_open__3VDIs .white_caret__3CWTF {
  -webkit-transform: translateY(-50%) rotateZ(180deg);
          transform: translateY(-50%) rotateZ(180deg);
}

.white_caret__3CWTF {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 5px;
}

.white_active__AwazY.white_disabled__1FIGD {
  cursor: not-allowed;
}

.white_item__1pa_M.white_activeItem__3MF5V {
  background: #ebf0fe;
  color: var(--game-blue);
}

.white_icon__1RGa4 {
  margin-right: 12px;
}

.white_label__11GyI {
  color: var(--night);
  font-size: 14px;
}

.white_item__1pa_M:hover {
  background: var(--game-blue);
}
.white_item__1pa_M:hover .white_itemLabel__3A-YD {
  color: var(--snow);
}

.white_itemLabel__3A-YD {
  white-space: nowrap;
}

.white_divider__1aotX {
  position: relative;
  margin-top: 4px;
}

.white_divider__1aotX:before {
  display: inline-block;
  content: '';
  top: -2.5px;
  left: 5px;
  width: calc(100% - 10px);
  height: 1px;
  background: #ededed;
  position: absolute;
}


.white_white__1NsNh {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: var(--white-header);
  background: var(--snow);
  border-bottom: 1px solid #ebebeb;
  font-family: 'Inter', sans-serif;
}

.white_white__1NsNh.white_withSidebar__1EPtO.white_withSidebar-full__5FkNi {
  transition:
    left var(--animation),
    width var(--animation);
  width: calc(100% - var(--white-sidebar-full));
  left: var(--white-sidebar-full);
}

.white_white__1NsNh.white_withSidebar__1EPtO.white_withSidebar-full__5FkNi.white_tinySidebar__rEE7R {
  width: calc(100% - var(--white-tiny-sidebar-full));
  left: var(--white-tiny-sidebar-full);
}

.white_white__1NsNh.white_withSidebar__1EPtO.white_withSidebar-minimize__2CH4D {
  width: calc(100% - var(--white-sidebar-minimize));
  transition:
    left var(--animation),
    width var(--animation);
  left: var(--white-sidebar-minimize);
}

.white_white__1NsNh.white_withSidebar__1EPtO.white_withSidebar-minimize__2CH4D.white_tinySidebar__rEE7R {
  width: calc(100% - var(--white-sidebar-minimize));
  transition:
    left var(--animation),
    width var(--animation);
  left: var(--white-sidebar-minimize);
}

.white_container__w7HK2 {
  margin: 0 auto;
  align-items: center;
  height: 100%;
  display: flex;
  width: 100%;
  padding: 0 20px;
  justify-content: space-between;
}

.white_logoWrapper__2xv_j {
  height: 100%;
  padding: 10px 0;
  margin-right: 16px;
}

.white_whiteLogoWrapper__3wj1v {
  height: 22px;
  margin-right: 16px;
}

.white_logoWrapper__2xv_j a {
  display: flex;
  height: 100%;
}

.white_logoWrapper__2xv_j img {
  height: 100%;
}

.white_menus__3-ntn {
  flex: 3 1;
}

.white_wrapper__3GEyX.white_white__1NsNh .white_menus__3-ntn {
  height: 100%;
}

.white_list__-1c8J {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0;
}

.white_link__3UBVe {
  color: var(--snow);
  background: #ffffff !important;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
}

.white_white__1NsNh .white_link__3UBVe.white_activeLink__qpHev {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

.white_link__3UBVe:hover {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

.white_white__1NsNh .white_link__3UBVe:hover {
  background: var(--game-blue);
  color: var(--snow);
}

.white_white__1NsNh .white_link__3UBVe {
  color: var(--night);
  font-size: 15px;
  border-radius: 60px;
  margin: 9px 6px;
  padding: 7px 12px;
  transition:
    color var(--animation),
    background var(--animation);
}

.white_whiteIcon__7k3j5 {
  margin-right: 13px;
}

.white_icon__2nqF- {
  margin-right: 8px;
}

.white_logoutLi__3fZ5O .white_icon__2nqF- {
  margin: 0;
  padding: 0 15px;
}

.white_rightSide__1PHvH .white_list__-1c8J .white_link__3UBVe.white_onlyIcon__3NHT2 .white_icon__2nqF- {
  padding: 0;
}

.white_rightSide__1PHvH .white_list__-1c8J li {
  margin-right: 0;
}

.white_logoutWrapper__-W2Mm {
  margin: 0;
  background: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
  outline: none;
}

.white_logoutWrapper__-W2Mm:hover {
  color: var(--snow);
}

.white_dropdownTrigger__ckHXz {
  color: var(--snow);
}

.white_whiteDropdownWrapper__1-BnQ {
  padding: 6px 0;
}

.white_dropdown__BBgDH {
  min-width: 130px;
  max-width: 130px;
  justify-content: flex-end;
}

.white_dropdown__BBgDH > div:first-child {
  max-width: 130px;
  flex-direction: row-reverse;
  width: auto;
  padding: 10px 6px;
}

.white_dropdown__BBgDH p {
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 35px;
}

.white_dropdown__BBgDH > div > span {
  margin: 0 0 0 4px;
}

.white_userDropdown__OZvTI {
  justify-content: flex-end;
}

.white_brands__2dbRi {
  background: var(--gray-input);
}

.white_label__2LVHn {
  color: inherit;
}

.white_popoverOverlay__15AMx .ant-popover-arrow {
  display: none;
}

.white_popoverOverlay__15AMx .ant-popover-inner {
  border-radius: 5px;
}
.white_popoverOverlay__15AMx .ant-popover-inner-content {
  position: relative;
  padding: 6px !important;
  display: flex;
  min-width: 180px;
  border-radius: 5px;
  border: 1px solid var(--new-light-gray-2);
  flex-direction: column;
  align-items: center;
  background: var(--snow);
}

.white_popoverWrapper__226Hj {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.white_popoverOverlay__15AMx div {
  padding: 0 !important;
}
.white_popoverBtn__7KD3i {
  background: var(--snow);
  border: none;
  color: var(--night);
  height: 32px;
  width: 180px;
  white-space: nowrap;
  padding: 4px 10px;
  display: flex;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.24px;
  outline: none;
  cursor: pointer;
}

.white_popoverBtn__7KD3i:hover {
  background: var(--game-blue);
  color: var(--snow);
}
.white_popoverBtn__7KD3i[disabled] {
  color: var(--game-blue-opacity);
  cursor: not-allowed;
}
.white_popoverBtn__7KD3i[disabled]:hover {
  color: var(--game-blue-opacity);
  background: var(--snow);
}

.white_unlockButton__1IV35 {
  margin-right: 8px;
}

.white_book__1vX7r img {
  margin-bottom: 3px;
}

@media all and (max-width: 1599px) {
  .white_label__2LVHn {
    font-size: 12px;
  }

  .white_dropdownTrigger__ckHXz p {
    font-size: 12px;
  }

  .white_link__3UBVe {
    padding: 4px 12px;
  }
}

.ticketModal_buttonsWrapper__At2pd {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.ticketModal_buttonsWrapper__At2pd .ticketModal_btn__21tIH {
  margin-left: 8px;
}

.ticketModal_hint__2x4CH {
  margin-bottom: 10px;
}

.ticketModal_modalOverlay__1e7sF {
  justify-content: flex-end;
  padding-right: 20px;
  background: transparent;
  box-shadow: 0px 16px 22px 0px #0000001a;
}

.ticketModal_modal__1GUqK {
  width: 650px !important;
  min-width: 650px !important;
}

.ticketModal_input__2FRQ0 {
  margin-bottom: 30px !important;
}

.ticketModal_input__2FRQ0 label {
  word-break: break-all;
  white-space: unset;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.ticketModal_textarea__ca4qM textarea {
  margin: 15px 30px 0;
  width: unset !important;
}

.upload_dndArea__jRuGF {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  min-height: 150px;
  border-radius: 10px;
  background: var(--pastel-blue);
  border: 2px dashed var(--light-blue);
  position: relative;
  margin: 10px 30px 0;
}

.upload_spinnerWrapper__vU47o {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: rgba(255, 255, 255, 0.7);
}

.upload_uploaderArea__kQSxE {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  min-height: 150px;
  position: relative;
}

.upload_filesPreview__3uQlh {
  width: 100%;
  border-bottom: 2px dashed var(--light-blue);
}

.upload_dndArea__jRuGF.upload_small__R4jZ_ {
  height: auto;
  padding: 5px;
  justify-content: unset;
  align-items: unset;
  flex-direction: unset;
  min-height: unset;
}

.upload_dndArea__jRuGF p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: var(--game-blue);
  letter-spacing: -0.3px !important;
}

.upload_removeWrapper__WJ6Lk {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  border-radius: 100%;
  background: var(--red-400);
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload_removeWrapper__WJ6Lk * {
  height: 12px;
  width: 12px;
  justify-content: center;
  align-items: center;
}

.upload_iconsWrapper__1RB9a {
  display: flex;
  flex-wrap: wrap;
}

.upload_iconsWrapper__1RB9a .upload_icon__AWB-_ {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--snow);
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  position: relative;
}

.upload_iconsWrapper__1RB9a .upload_icon__AWB-_ img {
  max-width: 100%;
  max-height: 100%;
}

.upload_inputFile__3IdVT {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.upload_buttonsWrapper__10gtk {
  display: flex;
}

.upload_inputFileBtn__3_ubT {
  position: relative !important;
  cursor: pointer !important;
}

.upload_inputFileBtn__3_ubT + .upload_inputFileBtn__3_ubT {
  margin-left: 4px;
}

.upload_dndArea__jRuGF.upload_canDrop__2bUV2 {
  border: 1px solid var(--green);
  background: var(--green-opacity);
}

.upload_row__274LE {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
}

.upload_imageWrapper__2BZ0Z {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload_iconWrapper__1ipAB {
  width: 40px;
  height: 40px;
  margin-bottom: -5px;
}

.upload_iconWrapper__1ipAB .upload_cloud__37JrP {
  width: 40px;
  height: 40px;
}

.upload_image__3D3pE {
  max-width: 70px;
  max-height: 70px;
}

.upload_remove__2s9K3 {
  width: 20px;
  height: 20px;
}

.new_slugTitle__3CmOi {
  margin-bottom: 11px;
  font-weight: 600 !important;
}

.new_footer__2imbm {
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.new_footer__2imbm button:not(:last-child) {
  margin-right: 16px;
}

.new_hint__2OQUz {
  margin-bottom: 10px;
}

.restriction_link__1z1an {
  margin-left: 8px;
}

.restriction_body__k_etn {
  text-align: center;
  padding-top: 20px;
}

.restriction_text__3JnHt {
  margin-bottom: 16px;
}

.message_wrapper__OVykj {
  display: flex;
  width: 100%;
  background: var(--snow);
  box-shadow:
    0 4px 8px rgba(67, 86, 100, 0.102956),
    0 8px 16px rgba(67, 86, 100, 0.0780977);
  border-radius: 12px;
  min-height: 78px;
  padding: 14px 64px 14px 180px;
  -webkit-animation: message_scale-up-center__1oB6t 0.2s;
          animation: message_scale-up-center__1oB6t 0.2s;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  pointer-events: all;
  position: relative;
  margin-bottom: 20px;
}

.message_messageModal__10qeR {
  position: absolute;
  border-radius: 12px;
  background: var(--snow);
  box-shadow:
    0 4px 8px rgba(67, 86, 100, 0.102956),
    0 8px 16px rgba(67, 86, 100, 0.0780977);
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-height: 400px;
  width: 800px;
  -webkit-animation: message_back-in-down__3R0op 1s;
          animation: message_back-in-down__3R0op 1s;
  display: flex;
  overflow: hidden;
  pointer-events: all;
  -webkit-transform-origin: top center;
          transform-origin: top center;
}

.message_overlay__2HPjX {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #100c31;
  transition: opacity 1s;
  opacity: 0;
}

.message_overlay__2HPjX.message_show__13HdW {
  opacity: 0.6;
}

.message_imageWrapper__1tk2X {
  width: 400px;
  min-width: 400px;
  max-height: 400px;
  height: auto;
}

.message_messageImage__3DpRk {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}

.message_messageText__XuEFe {
  padding: 10px 10px 10px 20px;
  width: 100%;
}

.message_buttonWrapper__1J3sa {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.message_buttonWrapper__1J3sa * {
  text-decoration: none !important;
}

.message_container__20Ny3 {
  display: flex;
  position: fixed;
  top: calc(var(--white-header) + 12px);
  flex-direction: column;
  z-index: 12;
  pointer-events: none;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 32px;
}

.message_wrapper__OVykj.message_closing__WmUIE {
  -webkit-animation: message_scale-down-center__3RYxh 0.25s;
          animation: message_scale-down-center__3RYxh 0.25s;
  -webkit-transform-origin: top center;
          transform-origin: top center;
}

.message_messageModal__10qeR.message_closing__WmUIE {
  -webkit-animation: message_back-in-up__2fIk6 1s forwards;
          animation: message_back-in-up__2fIk6 1s forwards;
}

.message_wrapper__OVykj.message_red__32m2C {
  background: linear-gradient(87.71deg, #e62e2e 0%, #ffffff 15.84%), #ffffff;
}

.message_wrapper__OVykj.message_yellow__3-FAv {
  background: linear-gradient(87.71deg, #ffaa1b 0%, #ffffff 15.84%), #ffffff;
}

.message_wrapper__OVykj.message_blue__1Jy3T {
  background: linear-gradient(87.71deg, var(--game-blue) 0%, #ffffff 15.84%), #ffffff;
}

.message_textWrapper__2emyi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.message_textWrapper__2emyi.message_hasButton__1s7-d {
  height: calc(100% - 45px);
}

.message_textWrapper__2emyi.message_popup__36SB5 * {
  max-width: 340px;
}

.message_close__s4_D6 {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background: #f3f4f8;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.message_closePopup__32Q3_ {
  top: 10px;
  right: 10px;
  -webkit-transform: unset;
          transform: unset;
}

.message_addy__2MYNl {
  position: absolute;
  bottom: 0;
  left: 0;
}

.message_text__2dpjB {
  color: var(--black);
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  /* identical to box height, or 156% */

  letter-spacing: -0.2px !important;
}

.message_grayText__12Boa {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  /* identical to box height, or 156% */

  letter-spacing: -0.2px !important;

  color: #808192 !important;
  display: flex;
}

.message_brandSlugPopup__UzyCr {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}

.message_link__2PaSZ {
  color: var(--game-blue) !important;
  cursor: pointer;
  text-decoration: underline;
  margin: 0 4px;
}

@-webkit-keyframes message_scale-up-center__1oB6t {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes message_scale-up-center__1oB6t {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes message_scale-down-center__3RYxh {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes message_scale-down-center__3RYxh {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@-webkit-keyframes message_back-in-down__3R0op {
  0% {
    -webkit-transform: translate(-50%, -300%) scale(0.7);
            transform: translate(-50%, -300%) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translate(-50%, -50%) scale(0.7);
            transform: translate(-50%, -50%) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes message_back-in-down__3R0op {
  0% {
    -webkit-transform: translate(-50%, -300%) scale(0.7);
            transform: translate(-50%, -300%) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translate(-50%, -50%) scale(0.7);
            transform: translate(-50%, -50%) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes message_back-in-up__2fIk6 {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }

  80% {
    -webkit-transform: translate(-50%, -50%) scale(0.7);
            transform: translate(-50%, -50%) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: translate(-50%, -300%) scale(0.7);
            transform: translate(-50%, -300%) scale(0.7);
    opacity: 0.7;
  }
}

@keyframes message_back-in-up__2fIk6 {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }

  80% {
    -webkit-transform: translate(-50%, -50%) scale(0.7);
            transform: translate(-50%, -50%) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: translate(-50%, -300%) scale(0.7);
            transform: translate(-50%, -300%) scale(0.7);
    opacity: 0.7;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  --black: #272727;
  --night: #000000;
  --header: 40px;
  --white-header: 56px;
  --sub-header: 250px;
  --leads-message: 36px;
  --white-sidebar-full: 260px;
  --white-tiny-sidebar-full: 185px;
  --animation: 0.3s ease-in-out;
  --white-sidebar-minimize: 64px;
  --campaign-left-side: 512px;
  --preview-header: 48px;
  --small-screen: 1599px;
  --input-fsz: 14px;
  --rte-btn-height: 28px;
  --input-padding: 8px;
  --input-height: 26px;
  --section-margin: 16px;
  --cobalt: #272727;
  --light-gray-table: #838383;
  --br: 10px;
  --progress-bar: 46px;
  --gray: #999999;
  --gray-500: #686872;
  --gray-200: #e6e6e8;
  --gray-input: #f8f8f8;
  --gray-300: #cdcdd0;
  --gray-350: #f3f4f8;
  --gray-400: #9b9ba2;
  --gray-400-a: rgba(155, 155, 162, 0.5);
  --gray-600: #373644;
  --gray-title-font: #6e6e6e;
  --gray-file-upload: #f0f1f5;
  --gray-background: #f0f1f5;
  --gray-subtitle: #5c6574;
  --gray-font: #4f4f4f;
  --new-gray: #f5f6f8;
  --gray-input-preview: #d8def0;
  --gray-placeholder: #bfbfbf;
  --border-gray: #dfdfdf;
  --selected-row: #edeff8;
  --dark-gray: #949494;
  --light-gray-font: #c4c4c4;
  --light-gray-font-transparent: rgba(196, 196, 196, 0.3);
  --green: #a3e254;
  --green-light: #ddf8bc;
  --game-green: #67b900;
  --green-500: #00ca5d;
  --green-opacity: rgba(163, 226, 84, 0.2);
  --light-gray: #cacaca;
  --light-gray-border: #c4c4c4;
  --sidebar: 92px;
  --blue-font: #1754af;
  --button-box-shadow-color: rgba(152, 178, 255, 0.5);
  --box-shadow: 0 0 0 2px var(--button-box-shadow-color);
  --game-blue: #3261ec;
  --game-blue-200: #0045ff;
  --game-blue-opacity: rgba(50, 97, 236, 0.2);
  --dark-game-blue: #1754af;
  --blue-line: #98b2ff;
  --red: #dc3500;
  --red-400: #e62e2e;
  --red-500: #ff1e62;
  --danger-red: #ff3a29;
  --red-opacity: rgba(220, 53, 0, 0.1);
  --dark-red: #c73000;
  --light-red: #f7b9a5;
  --light-red-font: #f7b9a5;
  --game-light-blue: #4977ff;
  --gray-bg: #f1f3f6;
  --snow: #ffffff;
  --blue: #37507b;
  --dark-blue: #102447;
  --game-yellow: #fdbb24;
  --shadow-yellow: #ffaa1b;
  --purple: #eb2f96;
  --purple-opacity: rgba(235, 47, 150, 0.1);
  --game-yellow-transparent: rgba(253, 187, 36, 0.1);
  --yellow: #f9b51f;
  --light-yellow: #fff5c7;
  --dark-yellow: #dd9b06;
  --pastel-blue: #eff2ff;
  --light-blue: #ccdaff;

  /*    shadows*/

  --new-shadow: 0px 16px 22px rgba(0, 0, 0, 0.1), 0px 34px 55px rgba(0, 0, 0, 0.05), 0px 13px 66px rgba(0, 0, 0, 0.07);
  --filter-shadow: drop-shadow(0px 8px 16px rgba(67, 86, 100, 0.02)) drop-shadow(0px 16px 32px rgba(67, 86, 100, 0.16));
  --btn-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);

  --game-blue-hover: #7091f2;
  --game-blue-active: #1e4edc;

  --new-blue: #0a5dff;
  /*    new colors*/
  --new-dark-gray: #b2b2b2;
  --new-gray-3: #828b9a;
  --new-gray-4: #f6f6f6;
  --new-gray-5: #a69fad;
  --new-gray-6: #f8f8f8;
  --color-charcoal-1: #2e384d;
  --new-gray-bg: #f6f7fa;
  --new-gray-500: #686872;
  --new-gray-600: #373644;
  --new-gray-7: #eeeeef;
  --new-gray-8: #697794;
  --new-gray-9: #9597a1;
  --new-gray-10: #d4d9e0;
  --new-night: #111111;
  --new-light-gray: #ebebeb;
  --new-light-gray-2: #e2e2ea;
  --new-light-gray-3: #fbfbfc;
  --new-yellow: #ffaa1b;
  --new-yellow-hover: #ffc460;
  --new-yellow-active: #f89b00;
  --new-green-500: #71c516;
  --new-green-400: #34b53a;
  --new-green-light: #e2fbd7;
  --new-peach: #fffaeb;
  --new-peach-2: #f8d4a2;
  --new-peach-3: #f6c9b6;
  --new-orange-500: #ff8500;
  --new-orange-danger: #dc6803;
  --new-orange-dangerous: #ab4f01;
  --new-purple: #8a24ff;
  --new-light-purple: #f9f5ff;
  --new-light-purple-2: #d8d1ff;
  --new-light-blue: #ebf0fe;

  --new-red: #ff1e62;
  --new-cherry: #ea2e96;
  --new-cherry-light: #ffebf4;
  --new-red-light: #fff1f3;
  --new-red-light-2: #fad5d5;

  --campaigns-list-sidebar-width: 240px;
  --campaigns-list-sidebar-width-small: 240px;
}

@media all and (max-width: 1599px) {
  body {
    --sidebar: 70px;
    --header: 30px;
    --leads-message: 26px;
    --input-height: 20px;
    --input-fsz: 12px;
  }
}

body.scroll-disabled {
  overflow-y: hidden;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: none !important;
  border-radius: 0 0 10px 10px !important;
}

.ant-modal-header {
  border-bottom: none !important;
  border-radius: 10px 10px 0 0 !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}

.ant-popover-title {
  border: none !important;
}

.ant-popover-inner {
  border-radius: 10px !important;
}

.ant-modal-footer:empty {
  padding: 0 !important;
}

.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
}

.ant-popover-buttons .ant-btn {
  border-radius: 15px !important;
  height: var(--input-height) !important;
  padding: 5px 12px !important;
  font-size: var(--input-fsz) !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ant-popover-buttons .ant-btn {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--gray-file-upload);
}

.ant-popover-buttons .ant-btn:hover {
  color: var(--dark-game-blue);
  background: var(--gray-input-preview);
  border: 1px solid var(--gray-input-preview);
}

.ant-popover-buttons .ant-btn:focus,
.ant-popover-buttons .ant-btn:active {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
}

.ant-popover-buttons .ant-btn[disabled] {
  background: var(--snow);
  color: var(--gray-input-preview);
  border: 1px solid var(--gray-file-upload);
}

.ant-popover-buttons .ant-btn.ant-btn-primary {
  background: var(--game-blue);
  color: var(--snow);
  border: 1px solid var(--game-blue);
}

.ant-popover-buttons .ant-btn.ant-btn-primary:hover {
  background: var(--dark-game-blue);
  border: 1px solid var(--dark-game-blue);
}

.ant-popover-buttons .ant-btn.ant-btn-primary:focus,
.ant-popover-buttons .ant-btn.ant-btn-primary:active {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  box-shadow: var(--box-shadow);
}

.ant-popover-buttons .ant-btn.ant-btn-primary[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
}

.ant-popover-message-title {
  font-size: var(--input-fsz) !important;
  color: var(--cobalt);
}

.ant-popover-message-title {
  font-size: var(--input-fsz) !important;
  color: var(--cobalt);
}

.ant-modal-confirm-btns .ant-btn {
  border-radius: 15px !important;
  height: var(--input-height) !important;
  padding: 5px 12px !important;
  font-size: var(--input-fsz) !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ant-modal-confirm-btns .ant-btn {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--gray-file-upload);
}

.ant-modal-confirm-btns .ant-btn:hover {
  color: var(--dark-game-blue);
  background: var(--gray-input-preview);
  border: 1px solid var(--gray-input-preview);
}

.ant-modal-confirm-btns .ant-btn:focus,
.ant-modal-confirm-btns .ant-btn:active {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
}

.ant-modal-confirm-btns .ant-btn[disabled] {
  background: var(--snow);
  color: var(--gray-input-preview);
  border: 1px solid var(--gray-file-upload);
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  background: var(--game-blue);
  color: var(--snow);
  border: 1px solid var(--game-blue);
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover {
  background: var(--dark-game-blue);
  border: 1px solid var(--dark-game-blue);
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary:focus,
.ant-modal-confirm-btns .ant-btn.ant-btn-primary:active {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  box-shadow: var(--box-shadow);
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
}

.ant-modal-confirm-title {
  font-size: var(--input-fsz) !important;
  color: var(--cobalt) !important;
}

.ant-modal-confirm-btns {
  display: flex;
}

.ant-modal-confirm .ant-modal-body {
  padding: 24px !important;
}

.ant-modal-confirm-body {
  display: flex;
  align-items: center;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Content--after-open {
  -webkit-animation: scale-up-center 0.2s;
          animation: scale-up-center 0.2s;
}

.ReactModal__Content--before-close {
  -webkit-animation: scale-down-center 0.2s;
          animation: scale-down-center 0.2s;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

#tidio-chat-iframe {
  width: 110px;
}

.message_modal__2WcHO {
  min-width: 800px;
}

.message_coin__1GxHW {
  position: absolute;
  top: 115px;
  right: 60px;
  height: 150px;
  width: 150px;
}

.message_wrapper__2wZHZ {
  width: 500px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message_title__2rEM_ {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
}

.message_description__Q125z {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: -0.5px;
  text-align: left;
  color: var(--gray-500);
  margin-top: 40px;
}

.message_buttonsWrapper__1ofuj {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.message_buttonsWrapper__1ofuj .message_btn__2X5N4 + .message_btn__2X5N4 {
  margin-left: 8px;
}

