.slugTitle {
  margin-bottom: 11px;
  font-weight: 600 !important;
}

.footer {
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer button:not(:last-child) {
  margin-right: 16px;
}

.hint {
  margin-bottom: 10px;
}
